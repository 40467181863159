import { useState, useEffect } from 'react';
import Help from './Help'
import Profile from './Profile'
import Links from './Links'
import Summary from './Summary'
import Activity from './Activity.jsx'
// import Feedback from './Feedback'
import { observer } from 'mobx-react-lite'
import { useQuery, gql } from '@apollo/client'



const DashboardLayout = observer(() => {
    const [currentCompanyId, setCurrentCompanyId] = useState('')
    const [currentRigId, setCurrentRigId] = useState('')

    const { data, loading, refetch } = useQuery(
        gql`
            query getWellSections($companyId: String!, $rigId: String!, $take: String!) {
                getWellSections(
                    companyId: $companyId,
                    rigId: $rigId,
                    take: $take,
                ) {
                    id
                    name
                    type
                    createdAt
                    updatedAt
                    data
                    well {
                        id
                        name
                        status
                    }
                }
            }
        `,
        {
            variables: {
                companyId: currentCompanyId.length > 0 ? currentCompanyId : '-',
                rigId: currentRigId.length > 0 ? currentRigId : '-',
                take: '20',
            },
            //onCompleted(data) {
            //    setIsLoading(true)
            //},
        }
    )

    useEffect(() => {
        if (currentCompanyId !== '') {
            refetch()
        }
    }, [currentCompanyId, refetch])

   
    return (
        <div className="dashboardWrapper">
            <div className="dashboardContainer">
                <Summary
                    currentCompanyId={currentCompanyId}
                    setCurrentCompanyId={setCurrentCompanyId}
                    currentRigId={currentRigId}
                    setCurrentRigId={setCurrentRigId}
                    queryData={data}
                    queryLoading={loading}
                />
                <Links />
                <Profile />
                <Activity 
                    currentRigId={currentRigId} 
                    currentCompanyId={currentCompanyId} 
                    queryData={data}
                    queryLoading={loading}
                    />
                <Help />
                {/* <Feedback /> */}
            </div>
        </div>
    );
})

export default DashboardLayout
