import {  useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { getLabel} from '../../../utils/helpers'
import { getStatistic, conversion, numberFormatter } from 'utils/helpers'
import { unitConversion } from '../../../utils/helpers'
import warning from '../../../assets/images/icons/icon_warning.svg'

const OverpullCalculatorTool = observer(() => {
    const {
        store: { OverpullCalculator, PipeTally, BHA, WellM, CasingTally, LinerTally, TrendSheet },
    } = useMst()
    const { locationState } = useContext(RouterContext)


    function getCurrentDSDepth() {
        const lastRow = getLastTrendSheetRow();
        return lastRow?.depth || 0;
    }

    function getLastTrendSheetRow() {
        if (TrendSheet) {
            if (TrendSheet.rows.length > 0) {
                return TrendSheet.rows[TrendSheet.rows.length - 1]
            }
        }
        return null
    }
    
    function processTally(tally: typeof PipeTally, name: string) {
        if(tally && tally.strings && totalLength < currentDepth){
            for(let s = 0; s < tally.strings.length; s++) {
                if(totalLength >= currentDepth) break;
                let stringLength = 0;
                
                const string = tally.strings[s];
                const pipeType =  string.getPipe(string.name || '');
                               

                for(let i = 0; i < string.list.data.length; i++) {
                    const pipe = string.list.data[i];
                    
                    const pipeLength = pipe.length;
                    if(pipe && pipeLength){
                        totalLength += pipeLength;
                        stringLength += pipeLength;
                        
                        if(totalLength >= currentDepth){
                            break;
                        }
                    }
                }
                if(stringLength > 0) {
                    const weightPerUnit = pipeType.weight;
                    const thisWeight = (locationState?.units === 'metric' ? conversion('weightDistance', 'impToMet', weightPerUnit) : weightPerUnit) * stringLength;
                    accumDryWeight += thisWeight;
                    const accumBouyoedWeight = accumDryWeight * bouyencyFactor;
                    const bouyedWeight = thisWeight * bouyencyFactor;
                    const tensileStrength = pipeType.tenStrength;
                    const maxOverpull = ((locationState?.units === 'metric' ? conversion('weight', 'impToMet', tensileStrength) : tensileStrength) * safetyFactor) - accumBouyoedWeight;
                    if(maxOverpull < minMaxOverpull) minMaxOverpull = maxOverpull;
                    
                    overpullCalcTable.push({
                        id: string.id,
                        name: name + (s+1).toString() + "\u00A0\u00A0(" + pipeType.name + ")", 
                        adjustedWeight: weightPerUnit,
                        length: stringLength, 
                        weight: thisWeight,
                        accumWeight: accumDryWeight,
                        accumBouyedWeight: accumBouyoedWeight,
                        bouyedWeight: bouyedWeight,
                        tensileStrength: tensileStrength,
                        maxOverpull: maxOverpull
                    });
                }
            } 
        }
    }
    
    const steelDensity = OverpullCalculator?.steelDensity || Number(getStatistic("defaultSteelDensity", locationState));
    const safetyFactor = OverpullCalculator?.safetyFactor || 0.85;
    const mudWeight = getLastTrendSheetRow()?.mwOut || 0;
    const bouyencyFactor = steelDensity > 0 ? (steelDensity - mudWeight) / steelDensity : 0;
    const blockWeight = (WellM?.emptyBlockWeight || 0) * 1000; //initial numbers in klbs for imperial and hybrid and in ton for metric

    let totalLength = 0;
    let accumDryWeight = 0;
    let minMaxOverpull = Number.MAX_SAFE_INTEGER;
       
    const overpullCalcTable : {id: string, name: string, adjustedWeight?: number, length: number, weight: number, accumWeight?: number,
        accumBouyedWeight?: number, bouyedWeight: number, tensileStrength?: number, maxOverpull?: number
    }[] = []
    const currentDepth = getCurrentDSDepth();
    

    if(BHA && BHA.parts && totalLength < currentDepth){
        let bhaWeight = 0;
        for(let i = 0; i < BHA?.parts.length; i++) {
            const part = BHA.parts[i];
            const partLength = part.length;
            bhaWeight += part.itemWeight;
            if(partLength){
                totalLength += partLength;
                if(totalLength >= currentDepth){
                    break;
                }                   
            }
            console.log(part.itemWeight + ' BHA part weight');
            console.log(partLength + ' BHA part length');
            console.log(bhaWeight + ' BHA dry weight');

           
        }
        if(totalLength > 0) {
            accumDryWeight += bhaWeight;
            const accumBouyoedWeight = accumDryWeight * bouyencyFactor;
            const bouyedWeight = bhaWeight * bouyencyFactor;
            console.log(accumBouyoedWeight);
            
            overpullCalcTable.push({
                id: "BHA",
                name: "BHA", 
                length: totalLength, 
                weight: bhaWeight, 
                bouyedWeight: bouyedWeight
            });
        }     
    }

    processTally(CasingTally, "Casing String ");
    processTally(LinerTally, "Liner String ");
    processTally(PipeTally, "String ");

    
    return (
        <div className="overpullCalculatorContainer">
            {/* <h2 style={{ color: 'dodgerblue', marginLeft: '8px' }}>Max Overpull</h2> */}
            <div className="overpullCalculatorSettings">
                            <div className="overpullCalculatorGrid2col" style={{ marginBottom: '10px' }}>
                                <div className="nowrap h25" style={{ }}>Safety Factor:</div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <input
                                        id="safetyMargin"
                                        onFocus={e => e.target.select()}
                                        type="number"
                                        value={(safetyFactor * 100).toFixed(0)}
                                        className="overpullCalculatorInput"
                                        onChange={((e) => {
                                            OverpullCalculator?.updateOverpullCalculator(
                                                OverpullCalculator?.id,
                                                'safetyFactor',
                                                'number',
                                                ((Number(e.target.value) || 0) / 100).toString())
                                        })}
                                    />
                                    <div className="itemUnit" style={{ fontSize: '12pt', marginLeft: '3px' }}>
                                        %
                                    </div>
                                </div>
                            </div>
                            <div className="overpullCalculatorGrid2col" style={{ marginBottom: '10px' }}>
                                <div className="nowrap h25" style={{  }}>Steel Density:</div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <input
                                        id="density"
                                        onFocus={e => e.target.select()}
                                        type="number"
                                        value={steelDensity}
                                        className="overpullCalculatorInput"
                                        onChange={((e) => {
                                            OverpullCalculator?.updateOverpullCalculator(
                                                OverpullCalculator?.id,
                                                'steelDensity',
                                                'number',
                                                e.target.value)
                                        })}
                                    />
                                    <div className="itemUnit" style={{ fontSize: '12pt', marginLeft: '3px' }}>
                                        ({getLabel('weightVolumeSg', locationState)})
                                    </div>
                                </div>
                            </div>
                    </div>
                    {totalLength === 0 ? (
                    <div style={{ color: '#0096FF', paddingBottom: '3rem', display: 'flex', flexDirection: 'row' }}>
                        <div><img alt="warning" src={warning} className="filter-blue" style={{ marginTop: '10px', width: '25px', height: '25px', marginRight: '20px' }} /></div>
                        <div style={{ marginTop: '11px', color: '#0096FF' }}>There is not enough total string length to calculate Max Overpull.</div>
                    </div>
                ) : ''}
            <div className="" style={{ backgroundColor: 'transparent', width: '100%' }}>
                {totalLength !== 0 ? (
                    <div>
                        <div className='overpullCalculatorString' style={{paddingBottom: '1rem'}}>
                            <div className="overpullCalculatorCols">
                                <span style={{fontWeight: '700'}}>Block weight: </span>
                                <div>
                                    <span>{numberFormatter(blockWeight.toString(), 0)}</span> 
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                </div>
                            </div>
                            <div className="overpullCalculatorCols">
                                <span style={{fontWeight: '700'}}>Mud weight: </span>
                                <div>
                                    <span>{mudWeight.toFixed(1)}</span> 
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('weightVolumeSg', locationState)}</span>    
                                </div>
                            </div>
                            <div className="overpullCalculatorCols">
                                <span style={{fontWeight: '700'}}>Buoyancy Factor: </span>
                                <div>
                                    <span>{(bouyencyFactor * 100).toFixed(2)}</span>
                                    <div className="overpullCalculatorTotalLabel" style={{ fontSize: '12pt', marginLeft: '3px' }}>%</div>
                                </div>
                            </div>
                        </div>
                    <div>
                     { overpullCalcTable?.map((row) => {
                        return (
                            <div key={row.id}>
                                    <div className="overpullCalculatorSectionHeader">
                                        <h3 style={{fontWeight: '700'}}>{row.name}</h3>
                                    </div>
                                    <div className="overpullCalculatorString" style={{paddingBottom: '2rem'}}>
                                        {row.adjustedWeight ? (
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Adjusted weight: </span>
                                            <div>
                                                <span>{locationState?.units === 'metric' ? numberFormatter(conversion('weightDistance', 'impToMet', row.adjustedWeight).toString(),2) : numberFormatter(row.adjustedWeight.toString(),2)}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weightDistance', locationState)}</span> 
                                            </div>
                                         </div>
                                        ) : ''}
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Length: </span>
                                            <div>
                                                <span> {numberFormatter(unitConversion('lengthMedium', locationState?.units, 'out', row.length).toString(), 2)}</span> 
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('lengthMedium', locationState)}</span>
                                            </div>
                                        </div>
                                        {row.tensileStrength ? (
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Tensile Strength:  </span>
                                            <div>
                                                <span> {locationState?.units === 'metric' ? numberFormatter(conversion('weight', 'impToMet', (row.tensileStrength)).toString(), 2) : numberFormatter(row.tensileStrength.toString(), 2)}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                            </div> 
                                        </div>) : ''}
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Dry Weight: </span>
                                            <div>
                                                <span>{numberFormatter(row.weight.toString(), 2)}</span> 
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                            </div>
                                        </div>
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Bouyed Weight: </span> 
                                            <div>
                                                <span>{numberFormatter(row.bouyedWeight.toString(), 2)}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span> 
                                            </div>
                                        </div>
                                        {row.accumWeight ? (
                                             <div className="overpullCalculatorCols">
                                             <span style={{fontWeight: '700'}}>Accumulated Dry Weight:  </span> 
                                             <div>
                                                <span>{numberFormatter(row.accumWeight.toString(), 2)}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                             </div>
                                         </div>
                                        ) : ''}
                                       {row.accumBouyedWeight ? (
                                         <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Accumulated Bouyed Weight: </span>
                                            <div>
                                                <span> {numberFormatter(row.accumBouyedWeight.toString(), 2)}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                             </div> 
                                        </div>
                                       ) : ''}
                                        {row.maxOverpull ? (
                                        <div className="overpullCalculatorCols">
                                            <span style={{fontWeight: '700'}}>Max Overpull:  </span> 
                                            <div>
                                                <span> {row.maxOverpull > 0 ? numberFormatter(row.maxOverpull.toString(), 2) : 0}</span>
                                                <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                             </div>
                                        </div>) : '' }
                                </div>
                            </div>
                        )
                     })}
                </div>
                <div className="overpullCalculatorTotalWrapper" style={{backgroundColor: 'rgb(229, 228, 226)'}}>
                        <div style={{borderBottom: '1px solid #000'}} className="overpullCalculatorTotalHeading">
                        <h3 style={{fontWeight: '700', color: '#000', marginBottom: '0.5rem'}}>Total: </h3>
                        </div>
                        <div className="overpullCalculatorString">
                        <div className="overpullCalculatorTotalCol">
                                <div style={{fontWeight: '700'}}>Total String Length: </div>
                                <div>
                                    <span>{numberFormatter(totalLength.toString(), 2)}</span>
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('lengthMedium', locationState)}</span>
                                </div> 
                            </div>
                            <div className="overpullCalculatorTotalCol">
                                <span style={{fontWeight: '700'}}>Total Weight (Minus Block): </span>
                                <div>
                                    <span>{numberFormatter((accumDryWeight * bouyencyFactor).toString(),2)}</span>
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                </div>
                            </div>
                            <div className="overpullCalculatorTotalCol">
                                <span style={{fontWeight: '700'}}>Total Weight (w/ Block): </span>
                                <div>
                                    <span>{numberFormatter((blockWeight + (accumDryWeight * bouyencyFactor)).toString(), 2)}</span>
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                </div>
                            </div>
                            <div className="overpullCalculatorTotalCol">
                                <span style={{fontWeight: '700'}}>Max Overpull: </span>
                                <div>
                                    <span>{minMaxOverpull > 0 ? numberFormatter(minMaxOverpull.toString(), 2) : 0}</span>
                                    <span className="overpullCalculatorTotalLabel">  {getLabel('weight', locationState)}</span>
                                </div>
                            </div>
                            <div style={{backgroundColor: 'rgba(255, 87, 121, 0.40)'}} className="overpullCalculatorTotalCol total-col">
                                <span style={{fontWeight: '700'}}>Max Wt.Indicator Reading: </span>
                                <div>
                                    <span>{numberFormatter(((minMaxOverpull > 0 ? minMaxOverpull : 0) + (blockWeight + (accumDryWeight * bouyencyFactor))).toString(),2)}</span>
                                    <span className="overpullCalculatorTotalLabel"> {getLabel('weight', locationState)}</span>
                                </div>
                            </div>
                        </div>       
                    </div>
                </div>

                ) : ''}
            </div>
          
        </div>
    )
})

export default OverpullCalculatorTool