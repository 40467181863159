import { observer } from 'mobx-react'
import { CheckMarkButton } from '../kit/RadioButton'
import { Paper } from '../Layout'
import { KeyboardEvent, useContext } from 'react'
import { RouterContext } from '../Router'
import { useMst } from 'app/store'
import TableToolbar from './TableToolbar'
import { unitConversion } from '../../../utils/helpers'

const NoDataRow = () => (
  <tr style={{ display: 'table-row', width: '100%' }}>
    <td style={{ textAlign: 'left', paddingLeft: '60px', width: '4%' }} colSpan={1}>
      {'No Data'}
    </td>
    <td style={{ width: '12%' }}>{'-'}</td>
    <td style={{ width: '6%' }}>{'-'}</td>
    <td style={{ width: '8%' }}>{'-'}</td>
    <td style={{ width: '8%' }}>{'-'}</td>
    <td style={{ width: '9%' }}>{'-'}</td>
    <td style={{ width: '9%' }}>{'-'}</td>
    <td style={{ width: '27%' }}>{'-'}</td>
    <td style={{ width: '6%' }}>{'-'}</td>
    <td style={{ width: '11%' }}>{'-'}</td>
  </tr>
)

interface TableHeadProps {
  cStr: string
}

const TableHead = ({ cStr }: TableHeadProps) => {

  const {
    store: { CasingTally },
  } = useMst()

  function selectAll(divId: string, sourceCheckbox: HTMLInputElement) {

    // add all input ids to the selected array
    for (let i = 0; i < (CasingTally?.strings.length || 0); i++) {
      if (CasingTally?.strings[i].id === cStr) {
        CasingTally?.strings[i].list.selectAll()
      }
    }

    // show them as checked
    const divElement = document.getElementById(divId) || document.createElement('div');
    let inputElements = divElement.getElementsByTagName('input');
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].type !== 'radio') {
        continue;
      } else {
        inputElements[i].checked = sourceCheckbox.checked;
      }
    }
  }

   //remember all checked rows
   let allChecked = false;
   for (let i = 0; i < (CasingTally?.strings.length || 0); i++) {
     if (CasingTally?.strings[i].id === cStr) {
       allChecked = CasingTally?.strings[i].list.allSelected;
       break;
     }
   }
   

  return (
  <thead style={{
    display: 'table-header-group',
    position: 'sticky',
    overflow: 'hidden',
    top: 0,
    zIndex: 1
  }}>
    <tr>
      <th colSpan={10}>
        <TableToolbar />
      </th> 
    </tr>
    <tr style={{ backgroundColor: 'rgb(62, 64, 86)', display: 'table-row', width: '100%' }}>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', borderRight: 'none', width: '4%' }}>
        <input
          id="inputCheckAll"
          onChange={(e) => selectAll('divTbl', e.target as HTMLInputElement)}
          type="checkbox"
          checked={allChecked}
        />
      </th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', textAlign: 'left', borderLeft: 'none', width: '12%' }}>
        Serial No.
      </th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '6%' }}>
        Stand No.
      </th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '8%' }}>Single</th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '8%' }}>Stand<br/>Length</th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '9%' }}>
        Pipe
        <br />
        Total
      </th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '9%' }}>
        Drill pipe<br />+ Liner
      </th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', textAlign: 'left', paddingLeft: '20px', width: '27%' }}>Comments</th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '6%' }}>POI</th>
      <th style={{ backgroundColor: 'rgb(62, 64, 86)', width: '11%' }}>
        Stick up mid
        <br />
        joint HOR
      </th>
    </tr>
  </thead>
  )
}

const TallyRow = (props: TallyRowProps) => {
  const {
    id,
    disabled,
    checked,
    onClick,
    serialNumber,
    standNumber,
    length,
    standLength,
    totalLength,
    comment,
  } = props
  const {
    store: { CasingTally, LinerTally },
  } = useMst()
  const { locationState } = useContext(RouterContext)
  const standNumberNumeric = !isNaN(Number(standNumber))

  function onPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur()
    }
  }

  function saveComment(id: string, comment: string) {
    if (CasingTally && CasingTally.currentString) {
      if (CasingTally?.strings.length > 0) {
        for (let i = 0; i < CasingTally.strings[CasingTally.currentString.number - 1].list.data.length; i++) {
          if (CasingTally.strings[CasingTally.currentString.number - 1].list.data[i].id === id) {
            CasingTally.strings[CasingTally.currentString.number - 1].setComment(i, comment)
          }
        }
      }
    }
  }

  function calcTotal(tl: string) {
    const drillPipe = Number(parseFloat(tl).toFixed(3))
    let priorLength = Number(0)

    // Get liner total length
    if (LinerTally) {
      for (let i = 0; i < LinerTally.strings.length; i++) {
        priorLength = +priorLength + +unitConversion('lengthMedium', locationState?.units, 'out', Number(LinerTally.strings[i].totalLength), 10)
      }
    }

    // Get casing total length from all previous strings [if exist]
    if (CasingTally && CasingTally.currentString) {
      if (CasingTally?.strings.length > 1) {
        for (let i = 0; i < CasingTally.currentString.number - 1; i++) {
          priorLength = +priorLength + +unitConversion('lengthMedium', locationState?.units, 'out', Number(CasingTally.strings[i].totalLength), 10)
        }
      }
    }

    return (+drillPipe + +priorLength).toFixed(3)
  }

  return (
    <tr style={{ display: 'table-row', width: '100%' }}>
      <td style={{ width: '4%' }}>
        <div style={{ zIndex: -1 }}>
          {!disabled ? (
            <CheckMarkButton
              id={`${serialNumber}-check-mark-button`}
              checked={checked}
              onClick={() => {onClick();}}
            />
          ) : null}
        </div>
      </td>
      <td style={{ textAlign: 'left', borderLeft: 'none', width: '12%' }}>{serialNumber}</td>
      <td style={{ width: '6%' }} className={standNumberNumeric ? 'hl' : ''}>
        {standNumber}
      </td>
      <td style={{ width: '8%' }} className={'hl'}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(length), 10).toFixed(3)}
      </td>
      <td style={{ width: '8%' }} className={standLength ? 'hl' : ''}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(standLength), 10) > 0 ? unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(standLength), 10).toFixed(3) : '-' || '-'}
      </td>
      <td style={{ width: '9%' }} className={'hl'}>
        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(totalLength), 10).toFixed(3)}
      </td>
      <td style={{ width: '9%' }} className={'hl'}>
        {calcTotal(unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(totalLength), 10).toFixed(3) || '0')}
      </td>
      <td style={{ width: '27%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginTop: '3px', width: '100%' }}>
            <input
              key={id}
              id={id}
              name={id}
              style={{
                minWidth: '100%',
                width: '100%',
              }}
              title="Comments"
              type="text"
              maxLength={500}
              defaultValue={comment}
              onClick={(e) => {
                e.stopPropagation()
              }}
              // onChange={(e) => {
              //   saveComment(e.target.name, e.target.value)
              // }}
              className="editableInput"
              onKeyDown={onPressEnter}
              onMouseLeave={(e) => {
                saveComment((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).value)
              }}
              onBlur={(e) => {
                saveComment(e.target.name, e.target.value)
              }}
            />
          </div>
        </div>
      </td>
      <td style={{ width: '6%' }}>{'-'}</td>
      <td style={{ width: '11%' }}>{'-'}</td>
    </tr>
  )
}

type TallyRowProps = {
  id: string
  disabled: boolean
  checked: boolean
  serialNumber: string | number
  standNumber: string
  length: string
  standLength: string
  totalLength: string
  bhaLength: number
  comment: string
  onClick: () => void
}

const CasingTally = observer(() => {
  const {
    store: { PipeTally, CasingTally },
  } = useMst()

  const isChecked = (id: string) =>
  CasingTally?.currentString.list.selected.indexOf(id) !== -1

  function flipSelectAll(force?: boolean) {
    const elem = document.getElementById('inputCheckAll') as HTMLInputElement
    if (elem) {
      if (force === true) {
        elem.checked = true
      } else {
        elem.checked = false
      }
    }
  }


  return (
    <Paper>
      <div id="divTbl">
        {/* <TableToolbar /> */}
        <table className="table" style={{ display: 'table', width: '99%' }}>
        <TableHead cStr={CasingTally?.currentString?.id || ''} />
          <tbody style={{ display: 'table-row-group' }}>
            {CasingTally?.currentString.length !== 0 ? (
              CasingTally?.currentString.tally.map(({ id, ...props }) => {

                return (
                <TallyRow
                  id={id}
                  key={props.serialNumber}
                  disabled={PipeTally?.editingMode === 'disabled'}
                  onClick={() => {
                    CasingTally.currentString.list.select(id)
                    if (CasingTally.currentString.list.selected.length === CasingTally.currentString.list.data.length)  {
                      flipSelectAll(true)
                    } else if (CasingTally.currentString.list.selected.length === 0) {
                      flipSelectAll(false)
                    } else {
                      flipSelectAll(false)
                    }
                  }}
                  checked={isChecked(id)}
                  {...props}
                />
              )})
            ) : (
              <NoDataRow />
            )}
          </tbody>
        </table>
      </div>
    </Paper>
  )
})

export default CasingTally
