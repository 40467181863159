//import { Nothing } from 'immer/dist/internal'
import Units from '../app/store/Units.json'
import {
  IAnyModelType,
  IReferenceType,
  ModelCreationType,
  types,
} from 'mobx-state-tree'
import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  //ReactChild,
  //ReactChildren,
} from 'react'

export function repeat(
  n: number,
  callback: (i: number) => void,
  countFrom = 0
) {
  for (let i = countFrom; i < n + countFrom; i++) {
    callback(i)
  }
}

export type CreationType<T extends IAnyModelType> = Parameters<
  ModelCreationType<T>['create']
>

export type LateReference = IReferenceType<IAnyModelType>
export const lateReference = (model: IAnyModelType) =>
  types.late((): LateReference => types.reference(model))

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
// export type ValidRecipeReturnType<State> =
//   | State
//   | void
//   | undefined
//   | (State extends undefined ? Nothing : never)

export const isNumeric = (s: any) => !isNaN(Number(s))

export function copy<T>(o: T): T {
  return Object.assign({}, o)
}
export namespace copy {
  export const array = <T>(a: T): T => Object.assign([], a)
  export const object: <T>(o: T) => T = copy
}

export function div(val: number, by: number) {
  return (val - (val % by)) / by
}

export type ChildrenProps = {
  children?: ReactNode
}
export type ParentProps = {
  parent?: ParentNode
}

export type JSXElementProps<T extends HTMLElement> = DetailedHTMLProps<
  HTMLAttributes<T>,
  T
>

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export const underscoreToSpace = (s: string) => s.replace(/_/g, ' ').replace(/~/g, ".")
export const spaceToUnderscore = (s: string) => s.replace(/ /g, '_').replace(/\./g, "~")

export const validateTwoDecimals = function(e: any) {
  var t = e.value;
  e.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
}

export const getHighestNumber = (numbers: number[]) => {
  return Math.max(...numbers)
}

export function cleanUpRogueCharacters(string: string) {
  return decodeURIComponent(string).replace(/[^a-zA-Z0-9 ]/g, '')
}

export function removeSingleQuotes(string: string) {
  return string.replace(/'/g, '')
}

export function removeNonAlphaNumericExceptPercent(string: string) {
  return string.replace(/[^a-zA-Z0-9% ]/g, '')
}

export function removeNonAlphaNumericExceptPercentAndHyphen(string: string) {
  return string.replace(/[^a-zA-Z0-9%-]/g, '')
}

export function removeNonAlphaNumericExceptSpacePeriodHyphen(string: string) {
  return string.replace(/[^a-zA-Z0-9. -]/g, '')
}

export function getRig() {
  const context = JSON.parse(window.localStorage.getItem('context') || '{}')
  return context?.rig?.id
}

export function getRigName() {
  const context = JSON.parse(window.localStorage.getItem('context') || '{}')
  return context?.rig?.name
}

export function getRigType() {
  const context = JSON.parse(window.localStorage.getItem('context') || '{}')
  return context?.rig?.type
}

export function getCompany() {
    const context = JSON.parse(window.localStorage.getItem('context') || '{}')
    return context?.company?.id
}

export function getCompanyName() {
  const context = JSON.parse(window.localStorage.getItem('context') || '{}')
  return context?.company?.name
}

export function trimString(string: string, length: number) {
  return string.length > length ? string.substring(0, length) + '...' : string
}

export function getLabelNoBrazilian(key: string, locationState: any) {
  const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
  if (conversion) {
    if (locationState) {
      if ((locationState.units === 'imperial') || (locationState.units === 'hybrid') ) {
        return conversion.impLabel
      }
      else if (locationState.units === 'metric') {
        return conversion.metLabel
      }
    }
  }
  return ''
}

export function getLabel(key: string, locationState: any) {
  const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
  if (conversion) {
    if (locationState) {
      if (locationState.units === 'imperial') {
        return conversion.impLabel
      }
      else if (locationState.units === 'metric') {
        return conversion.metLabel
      }
      else if (locationState.units === 'hybrid') {
        return conversion.hybLabel
      }
    }
  }
  return ''
}

export function getStatistic(key: string, locationState: any) {
  const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
  if (conversion) {
    if (locationState?.units === 'imperial') {
      return conversion.impRate
    }
    else if (locationState?.units === 'metric') {
      return conversion.metRate
    }
    else if (locationState?.units === 'hybrid') {
      return conversion.hybRate
    }
  }
  return 0
}

export function fixNumber(num: number, digits: number) {
  return Number(num.toPrecision(digits))
}

export function conversion(measurement: string, direction: string, val: number) {
  const conversion = Units[Units.findIndex(({ handle }) => handle === measurement)]
  if (conversion) {
    if (direction === 'impToMet') {
      return val * parseFloat(conversion.impToMet)
    }
    else if (direction === 'metToImp') {
      return val * parseFloat(conversion.metToImp)
    }
  }
  return 0
}

export function numberFormatter(value:string, decimal:number) {
  return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
    "en-US",
    {
      useGrouping: true,
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    }
  )
}


export function insertString(string: string, index: number, stringToInsert: string) {
  return string.slice(0, index) + stringToInsert + string.slice(index)
}

export function unitConversion(measurement: string, units = 'imperial', direction = 'out', num = 0, precision = 15) {
  num = units === 'hybrid' ?
    fixNumber(conversion(measurement, direction === 'out' ? 'impToMet' : 'metToImp', num || 0), precision)
    : fixNumber(num || 0, precision)
  return num
}

export function getUuid() {
  var uuid = "", i, random;

  for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
          uuid += "-";
      }

      // eslint-disable-next-line no-mixed-operators
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
   }

   return uuid;
  }

  export function checkIsNumber(val: number) {
    if (isNaN(val)) { val = 0 }
    if (!isFinite(val)) { val = 0 }
    return val
  }
