import { useQuery, gql, useMutation } from '@apollo/client'
import { useContext, useState, useEffect, Key } from 'react'
// import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { RouterContext } from '../Router'
import { UserContext } from 'App'
import { UserFields } from 'api/fragments'
import { getCompany, getRig, getRigName, getCompanyName } from 'utils/helpers'
import Clock from '../Well/Clock'
import { underscoreToSpace, trimString } from '../../../utils/helpers'
import { Button } from '../kit/SmallWidthButton'
import Slider from '@mui/material/Slider'
import Input from '@mui/material/Input'
import { Toast } from '../kit/Toast'
import { AlertProps } from '@mui/material/Alert';
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { getLabel } from '../../../utils/helpers'
import icon_close from '../../../assets/images/icons/icon_close.svg';

export type User = {
    id: string
    email: string
    password: string
    firstName: string | null
    secondName: string | null
    companyId: string | null
    rigId: string | null
    createdAt: Date
    permissions: string[]
    scope: string[]
    company: {
        id: string
        name: string
    }
    rig: {
        id: string
        name: string
        type: string
    }
} | null

export type trip = {
    id: string
    stub: number
    volslug: number
    mwslug: number
    mw: number
    discrepancyAlarm: number
    rows: tripRow[]
}

export type tripRow = {
    id: string
    index: number
    source: string
    sourceId: string
    deleted: boolean
    name: string
    pipeTypeId: string
    pipeId: string
    standLength: number
    string: number
    standNumber: number
    length: number
    serialNumber: string
    description: string
    partType: string
    OD: number
    ID: number
    disp: number
    cap: number
    weight: number
    emptyFill: string
    tt1: number
    tt2: number
    openClosed: string
    measHoleIncrem: number
    measHoleAccum: number
    calcIncrem: number
    calcAccum: number
    discIncrem: number
    discAccum: number
    comment: string
    tsComment: string
}

export type Snapshot = {
    id: string
    wellSectionId: string
    type: string
    createdAt: Date
    data: JSON
}

export type SnapshotListItem = {
    id: string
    wellSectionId: string
    type: string
    createdAt: Date
}

export type Snapshots = {
    [x: string]: any
    getSnapshots: SnapshotListItem[]
}

const Settings = observer(() => {
    const {
        store: { PipeTally, TripSheet },
    } = useMst()
    const [msg, setMsg]= useState('')
    const [showMsg, setShowMsg] = useState(false)
    const [variant, setVariant] = useState<AlertProps["color"]>("success")
    const { user, setUser } = useContext(UserContext)
    const [snapshotId, setSnapshotId] = useState('')
    const [snapshot, setSnapshot] = useState<Snapshot | null>(null)
    const [snapshots, setSnapshots] = useState<Snapshots | null>(null)
    const [snapshotTripsheet, setSnapshotTripsheet] = useState<any>(null)
    const [discrepancyAlarm, setDiscrepancyAlarm] = useState<number>(0)
    const { locationState } = useContext(RouterContext)
    const router = useLocation()
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    const { loading: getProfile } = useQuery<{ me: User }>(
        gql`
            ${UserFields}
            query me {
            me {
                ...UserFields
                company {
                    name
                }
            }
        }
        `,
        {
            onCompleted(data) {
                setUser && setUser(data.me)
            },
            onError(err) {
                console.error(err)
            },
        }
    )

    const useStyles = makeStyles({
        root: {
          width: 150,
        },
        input: {
          width: 42,
        },
    })
    const classes = useStyles()

    const handleSliderChange = (event: any, newValue: number) => {
        setDiscrepancyAlarm(newValue)
        TripSheet?.updateDiscrepancyAlarm(newValue)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscrepancyAlarm(event.target.value === '' ? 0 : Number(event.target.value))
        TripSheet?.updateDiscrepancyAlarm(parseInt(event.target.value))
    }

    const handleBlur = () => {
        if (discrepancyAlarm < 0) {
            setDiscrepancyAlarm(0)
        } else if (discrepancyAlarm > 30) {
            setDiscrepancyAlarm(30)
        }
    }

    const [createSnapshot] = useMutation(
        gql`
          mutation createSnapshot($well: String!, $wellSection: String!, $type: String!, $rig: String!, $company: String!, $data: JSON!) {
            createSnapshot(
                well: $well
                wellSection: $wellSection
                type: $type
                rig: $rig
                company: $company
                data: $data
            ) {
              id
            }
          }
        `,
        {
          variables: {
            well: underscoreToSpace(router.pathname.split('/')[2]),
            wellSection: underscoreToSpace(router.pathname.split('/')[3]),
            type: 'tripSheet|' + PipeTally?.editingMode,
            company: getCompany(),
            rig: getRig(),
            data: JSON.stringify(TripSheet)
        },
          onCompleted() {
            console.log('Snapshot created')
            setVariant('success')
            setMsg('Snapshot created')
            setShowMsg(true)
            reloadDropdown()
          },
        }
    )

    const { refetch: reloadDropdown, data, loading } = useQuery(
        gql`
          query getSnapshots($well: String!, $wellSection: String!, $take: String!, $type: String!, $rig: String!, $company: String!) {
            getSnapshots(
                well: $well,
                wellSection: $wellSection,
                take: $take,
                type: $type,
                rig: $rig,
                company: $company,
              ) {
              id
              wellSectionId
              type
              createdAt
            }
          }
        `,
        {
          variables: {
            well: underscoreToSpace(router.pathname.split('/')[2]),
            wellSection: underscoreToSpace(router.pathname.split('/')[3]),
            type: 'tripSheet',
            company: getCompany(),
            rig: getRig(),
            take: '250',
          },
          onCompleted(data) {
            setSnapshots(data.getSnapshots)
            // checkForNoSnapshots(data.getSnapshots)
          },
        }
    )

    const { refetch, data: snapshotData } = useQuery(
        gql`
          query getSnapshot($id: String!, $rig: String!, $company: String!) {
            getSnapshot(
                id: $id,
                rig: $rig,
                company: $company,
              ) {
              id
              wellSectionId
              type
              createdAt
              data
            }
          }
        `,
        {
          variables: {
            id: snapshotId,
            company: getCompany(),
            rig: getRig(),
          },
          onCompleted(data) {
            setSnapshot(snapshotData)
          },
        }
    )

    useEffect(() => {
        reloadDropdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (showMsg === true) setShowMsg(false)
      }, [showMsg])

    useEffect(() => {
        setDiscrepancyAlarm(TripSheet?.discrepancyAlarm || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDiscrepancyAlarm(TripSheet?.discrepancyAlarm || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TripSheet])

    useEffect(() => {
        if (snapshotData && snapshotData.getSnapshot && snapshotData.getSnapshot[0]) {
            setSnapshotTripsheet(JSON.parse(JSON.stringify(snapshotData.getSnapshot[0].data)))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshot])

    useEffect(() => {
        const tripSnap: trip = JSON.parse(snapshotTripsheet)
        if (tripSnap && tripSnap.rows && tripSnap.rows.length > 0) {
            resizeSnapshotContainer(tripSnap)
        }
    }, [snapshotTripsheet])

    function resizeSnapshotContainer(snapData: any) {
        let sw = document.getElementById('snapshotWrapper')
        if (sw) sw.style.height = snapData.rows?.length * 23 + 100 + 'px'
    }

    function clearSnapshotViewer() {
        // none of this is required and will cause ui issues
        /*
        let dd = document.getElementById('snapShotSelect') as HTMLSelectElement
        if (dd) dd.selectedIndex = 0

        let sc = document.getElementById('snapShotContainer')
        if (sc) sc.innerHTML = ''
        if (sc) sc.style.height = '60px'

        let sw = document.getElementById('snapshotWrapper')
        if (sw) sw.style.height = '100px'
        */
    }

    function showSnapshotViewer(id: string) {
        if (snapshots) {
            showSnapshot()
        }
    }


    function showSnapshot() {
        const x = document.getElementById("snapShotContainer");
        if (x) {
            x.style.display = "block";
        }
    }

    function showSnapshots() {
        const x = document.getElementById("snapshotViewer");
        if (x) {
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
                clearSnapshotViewer()
            }
        }
    }

    function getOptionLabel(dts: Date, type: string) {
        const labelDate = isoFormatDMY(dts)
        let labelDirection = ''
        try {
            labelDirection = type.split('|')[1]
        } catch {
            labelDirection = ''
        }

        if (labelDirection === undefined) {
            labelDirection = ''
        } else {
            labelDirection = ' - ' + labelDirection
        }

        return labelDate + labelDirection
    }

    function isoFormatDMY(d: Date) {
        const date = new Date(d)
        const month = date.toLocaleString('default', { month: 'short' })
        const dayOfWeek = weekday[date.getDay()]
        const day = date.getDate()
        const year = date.getFullYear()
        const hours = padZeroes(date.getHours())
        const minutes = padZeroes(date.getMinutes())
        const seconds = padZeroes(date.getSeconds())
        return `${dayOfWeek} ${month} ${day}, ${year} at ${hours}:${minutes}:${seconds}`
    }

    function padZeroes(num: number) {
        if (num < 10) {
            return '0' + num
        }
        return num
    }

    return !getProfile ? (
        <div className="" style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0px', width: '100%', marginBottom: '8px' }}>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'flex-end', width: '100%', textAlign: 'right'}}>
                        <Button
                            id="btnSaveSnapshot"
                            small
                            secondary
                            style={{ whiteSpace: 'nowrap', border: '1px solid #1974D2', justifyContent: 'flex-end', justifyItems: 'flex-end', marginRight: '10px' }}
                            onClick={() => { createSnapshot() }}
                        >Save Snapshot</Button>
                        <Button
                            id="btnViewSnapshots"
                            small
                            secondary
                            style={{ whiteSpace: 'nowrap', border: '1px solid #1974D2', justifyContent: 'flex-end', justifyItems: 'flex-end' }}
                            onClick={() => {
                                showSnapshots()
                            }}
                        >View Snapshots</Button>
                    </div>
                </div>
            </div>
            <div id="snapshotViewer" style={{ display: 'none' }}>
                <div className="tripSettings" style={{ display: 'flex', flexDirection: 'column', columnGap: '0px', width: '100%', marginBottom: '8px' }}>
                    <div style={{ color: 'black', marginBottom: '5px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ paddingLeft: '8px', whiteSpace: 'nowrap', justifyContent: 'flex-start', justifyItems: 'flex-start', alignItems: 'flex-start' }}>
                            <span style={{ fontWeight: 600, color: 'silver' }}>Historical Snapshots</span>
                            <select
                                id="snapShotSelect"
                                style={{ marginLeft: '10px', paddingLeft: '5px', width: '300px', height: '30px', border: '1px solid #1974D2', borderRadius: '5px', backgroundColor: 'white' }}
                                onChange={(e) => {
                                    showSnapshotViewer(e.target.value)
                                    setSnapshotId(e.target.value)
                                    const snapshotId = e.target.value
                                    refetch({
                                        id: snapshotId,
                                        rig: getRig(),
                                        company: getCompany()
                                    })
                                }}
                            >
                                <option value="0">--------- Select a Snapshot ---------</option>
                                {!loading && data && data.getSnapshots && data.getSnapshots.length > 0 ? (
                                    data.getSnapshots.map((snapshot: any) => {
                                        return (
                                        <option key={snapshot.id} value={snapshot.id}>{
                                            getOptionLabel(snapshot.createdAt, snapshot.type)
                                        }</option>
                                        )
                                    })
                                    ): ('')
                                }
                            </select>
                        </div>
                        <div style={{ width: '60%' }}></div>
                        <div style={{ whiteSpace: 'nowrap', justifyContent: 'flex-end', justifyItems: 'flex-end', alignItems: 'flex-end' }}>

                            <button
                                id="btnCloseSnapshots"
                                title='Close Snapshots'
                                style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent', display: 'flex', flexDirection: 'row' }}
                                onClick={() => {
                                    showSnapshots()
                                    clearSnapshotViewer()
                                }}
                            >
                                <div style={{ marginTop: '4px', marginRight: '10px', fontWeight: 600, color: 'silver' }}>Close</div>
                                <div>
                                    <img className="iconSvg" title='Close Snapshots' alt="Close Snapshots" src={icon_close} style={{ color: 'dodgerblue', marginTop: '0px', width: '25px', height: '25px', marginRight: '-0px' }} />
                                </div>
                            </button>
                        </div>
                    </div>
                    <div id="snapshotWrapper" className="tripSettings" style={{ marginTop: '8px', marginBottom: '0px', padding: '0px', backgroundColor: '#fff', width: '100%' }}>
                        <div id="snapShotContainer" style={{ backgroundColor: '#fff', color: '#000000', margin: '0px', padding: '0px', display: 'none', width: '100%', borderRadius: '8px 0px 0px 0px' }}>
                            <table style={{
                                whiteSpace: 'nowrap',
                                border: 'none',
                                width: 'auto',
                                }} className="tbl snapshotTable snpTable"
                            >
                                <thead>
                                    <tr>
                                        <td className="snapTableHeader" style={{ paddingLeft: '4px', borderRadius: '8px 0px 0px 0px' }}><br />Source</td>
                                        <td className="snapTableHeader" style={{ width: '150px' }}><br />Pipe Name</td>
                                        <td className="snapTableHeader"><br />Stand</td>
                                        <td className="snapTableHeader">Trip<br />Tank 1</td>
                                        <td className="snapTableHeader">Trip<br />Tank 2</td>
                                        <td className="snapTableHeader"><br />Open?</td>
                                        <td className="snapTableHeader">Open<br />End Disp</td>
                                        <td className="snapTableHeader">Closed<br />End ap</td>
                                        <td className="snapTableHeader">Ms Hole<br />Increm</td>
                                        <td className="snapTableHeader">Ms Hole<br />Accum</td>
                                        <td className="snapTableHeader">Calc<br />Increm</td>
                                        <td className="snapTableHeader">Calc<br />Accum</td>
                                        <td className="snapTableHeader">Disc<br />Increm</td>
                                        <td className="snapTableHeader">Disc<br />Accum</td>
                                        <td className="snapTableHeader" style={{ width: '150px' }}><br />Comment</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="snapTableCell" colSpan={1}>stub</td>
                                        <td className="snapTableCell" colSpan={2}></td>
                                        <td className="snapTableCell">{JSON.parse(snapshotTripsheet)?.stub.toFixed(1)}</td>
                                        <td className="snapTableCell" colSpan={3}></td>
                                        <td className="snapTableCell" colSpan={9}></td>
                                    </tr>
                                    {JSON.parse(snapshotTripsheet)?.rows.map((row: tripRow, id: Key | null | undefined) => (
                                        <tr key={id}>
                                            <td className="snapTableCell">{row.source}</td>
                                            <td
                                                className="snapTableLongTextField"
                                                title={row.description}
                                                style={{ width: '125px' }}
                                            ><abbr id="abbrDesc" title={row.description}>{trimString(row.description, 20)}</abbr></td>
                                            <td className="snapTableCell">{row.standNumber / row.standLength}</td>
                                            <td className="snapTableCell snapRight">{row.tt1.toFixed(1)}</td>
                                            <td className="snapTableCell snapRight">{row.tt2.toFixed(1)}</td>
                                            <td className="snapTableCell">{row.openClosed}</td>
                                            <td className="snapTableCell snapRight">{row.disp.toFixed(4)}</td>
                                            <td className="snapTableCell snapRight">{(row.cap + row.disp).toFixed(4)}</td>
                                            <td className="snapTableCell snapRight">{row.measHoleIncrem.toFixed(2)}</td>
                                            <td className="snapTableCell snapRight">{row.measHoleAccum.toFixed(2)}</td>
                                            <td className="snapTableCell snapRight">{row.calcIncrem.toFixed(2)}</td>
                                            <td className="snapTableCell snapRight">{row.calcAccum.toFixed(2)}</td>
                                            <td className="snapTableCell snapRight">{row.discIncrem.toFixed(2)}</td>
                                            <td className="snapTableCell snapRight">{row.discAccum.toFixed(2)}</td>
                                            <td
                                                className="snapTableLongTextField"
                                                style={{ width: '125px', minWidth: '125px' }}
                                            ><abbr
                                                data-tooltips
                                                id="abbrComment"
                                                data-tooltips-label="COMMENT"
                                                data-tooltips-message={row.tsComment}>{trimString(row.tsComment, 20)}</abbr></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
                <div className="tripSettings">
                    <div className="tripContext">
                        <div style={{ marginBottom: '10px' }}>Company</div>
                        <div style={{ marginBottom: '10px' }}>Driller</div>
                        <div>Direction</div>
                    </div>
                    <div className="tripContextData">
                        <div style={{ marginBottom: '10px' }}>{getCompanyName()}</div>
                        <div style={{ marginBottom: '10px' }}>{user?.firstName} {user?.secondName}</div>
                        <div>{PipeTally?.editingMode || 'RIH'}</div>
                    </div>
                    <div className="tripLocation">
                        <div style={{ marginBottom: '10px' }}>Rig</div>
                        <div style={{ marginBottom: '10px' }}>Well</div>
                        <div style={{ marginBottom: '10px' }}>Well Section</div>
                    </div>
                    <div className="tripLocationData">
                        <div style={{ marginBottom: '10px' }}>{getRigName()}</div>
                        <div style={{ marginBottom: '10px' }}>{underscoreToSpace(window.location.href.split('/')[4])}</div>
                        <div>{underscoreToSpace(window.location.href.split('/')[5])}</div>
                    </div>
                    <div className="tripClockAlarm">
                        <div className="tripClock" style={{ display: 'flex', flexDirection: 'row', columnGap: '0px', width: '100%', marginBottom: '50px' }}>
                            <div style={{ width: '75px', marginRight: '15px' }}>Date: </div>
                            <div className="wellInfoTimeClockWrapper"
                                style={{ marginTop: '-23px', width: '200px' }}
                            >
                                <div style={{ fontSize: '14px', color: 'silver' }} className="timeclock wellInfoTimeClock"><Clock /></div>
                                <div style={{ fontSize: '14px', color: 'silver' }} id="timeclock" className="timeclock"></div>
                            </div>
                        </div>
                        <div className="tripAlarm" style={{ display: 'flex', flexDirection: 'row', width: '400px', marginBottom: '20px' }}>
                            <div style={{ width: '175px', marginTop: '3px', textAlign: 'left', backgroundColor: 'transparent' }}>Discrepancy Alarm ({getLabel('volume', locationState)})</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '150px', paddingLeft: '10px' }}>
                                    <Slider
                                        value={discrepancyAlarm}
                                        onChange={(e, newValue) => {handleSliderChange(e, newValue as number)}}
                                        aria-labelledby="input-slider"
                                        min={0}
                                        max={30}
                                        valueLabelDisplay="on"
                                    />
                                </div>
                                <div style={{ width: '75px', visibility: 'hidden' }}>
                                    <Input
                                        id="discrepancyAlarmInput"
                                        className={classes.input}
                                        value={discrepancyAlarm}
                                        margin="dense"
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 50,
                                            type: 'number', 'aria-labelledby': 'discrepancyAlarmInput'
                                    }}
                                    /><br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast
              variant={variant}
              message={`${msg}`}
              open={showMsg}
            />
        </div>
    ) : null
})

export default Settings