import { useContext, useEffect } from 'react'
import { RouterContext } from '../Router'
import TableToolbar from './TableToolbar'
import { SectionTabs } from '../WellSection/SectionTabs'
import { useMst } from 'app/store'
import { PipeTallyInstance } from 'app/models/PipeTally'
import { BHAInstance } from 'app/models/BHA'
import { observer } from 'mobx-react'
import { getStatistic, cleanUpRogueCharacters, unitConversion } from 'utils/helpers'
import { CSVLink } from 'react-csv'
import Settings from './Settings'
import TripTable from './TripTable'
import './TripSheet.css'
import TripSheetRow from 'app/models/TripSheet/TripSheetRow'


const TripSheetUi = observer(() => {
    const {
        store: { BHA, CasingTally, LinerTally, PipeTally, TripSheet, TrendSheet },
    } = useMst()
    const { locationState } = useContext(RouterContext)
    const isOpenHole = locationState?.wellSectionType === 'OH'

    interface BitLocation {
        type: string
        container: number
        identifier: number
        depthRecorded: number
        depthTotal: number
        depthWhereFound: number
        bitLocated: boolean
    }

    useEffect(() => {
        if (TripSheet && TrendSheet && TrendSheet.rows.length > 0) {
            if (TripSheet?.rows.length > 0) {

                const bitLocation = whereIsTheBit()

                // disable the disabled editing mode
                if ((PipeTally?.editingMode === 'disabled') || (PipeTally?.editingMode === undefined)) {
                    PipeTally?.setEditingMode('RIH')
                }

                // delete the default row upon tripsheet creation (if exists [index=1])
                let TripSheetDefaultRowId = ''
                TripSheetDefaultRowId = TripSheet.getTripSheetRowByIndex(1)?.id || ''

                if (TripSheetDefaultRowId.length > 0) {
                    TripSheet?.markDeleted(TripSheetDefaultRowId)
                }

                // mark all existing rows deleted
                if (TripSheet?.rows.length > 0) {
                    TripSheet.rows.forEach((row) => {
                        TripSheet.markDeleted(row.id)
                    })
                }

                if (PipeTally?.editingMode === 'RIH') {
                    if (isOpenHole) {
                        if (BHA) {
                            genTripSheetBhaRows(BHA, 'bha', PipeTally.editingMode, bitLocation)
                        }
                    } else {
                        if (LinerTally) {
                            genTripSheetTallyRows(LinerTally, 'liner', PipeTally.editingMode, bitLocation)
                        }
                        if (CasingTally) {
                            genTripSheetTallyRows(CasingTally, 'casing', PipeTally.editingMode, bitLocation)
                        }
                    }
                    if (PipeTally) {
                        genTripSheetTallyRows(PipeTally, 'pipe', PipeTally.editingMode, bitLocation)
                    }
                } else if (PipeTally?.editingMode === 'POOH') {

                    if (PipeTally) {
                        genTripSheetTallyRows(PipeTally, 'pipe', PipeTally.editingMode, bitLocation)
                    }
                    if (isOpenHole) {
                        if (BHA) {
                            genTripSheetBhaRows(BHA, 'bha', PipeTally.editingMode, bitLocation)
                        }
                    } else {
                        if (LinerTally) {
                            genTripSheetTallyRows(LinerTally, 'liner', PipeTally.editingMode, bitLocation)
                        }
                        if (CasingTally) {
                            genTripSheetTallyRows(CasingTally, 'casing', PipeTally.editingMode, bitLocation)
                        }
                    }
                }

                deleteTripSheetRows()
                processTripSheetCalculations()
            }
        }

    // console.log('TRIPSHEET', TripSheet)
    // console.log('TRIPSHEET', JSON.stringify(TripSheet))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BHA, CasingTally, LinerTally, PipeTally, isOpenHole, TripSheet])

    function genStandMatrix(tally: PipeTallyInstance, direction: string, alias: string, bitLocation: BitLocation) {
        // generate a matrix of rows to be used in displaying the tripsheet

        // set stand specific permanent industry-standard tripsheet span
        const standSpan = 5
        // let pullMark = 0
        let maxIncludedStand = 0

        // create stand lookup list
        let standMatrix = new Array(tally.strings.length).fill(0).map(() => new Array(7).fill(0))

        // temp array
        let numberedStands = []
        let includedNumberedStands = []

        // remove starter matrix rows
        for (let i = 0; i < tally.strings.length; i++) {
            standMatrix.shift()
        }

        for (let s = 0; s < tally.strings.length; s++) {

            // resets
            maxIncludedStand = 0
            numberedStands = []
            includedNumberedStands = []

            // prep a stand reference list for the string
            const markingRefList = tally.strings[s].getReferenceStandList();
            //remember the last number
            let lastStandNumberInStringBeforeChange = 0;
            for (let i = 0; i < tally.strings[s].list.data.length; i++) {
                let marking = markingRefList[(tally.strings[s].list.data[i].standNumber as number) - 1];
                if(Number.isInteger(parseInt(marking))) {
                    //remember marking in a loop as the last number 
                    lastStandNumberInStringBeforeChange = parseInt(marking);
                } else if(i === tally.strings[s].list.data.length - 1) {
                    //if this is the last pipe in the matrix always add marking number
                    marking = (lastStandNumberInStringBeforeChange + 1).toString();
                }
                standMatrix.push([
                    s,
                    tally.strings[s].list.data[i].standNumber,
                    marking,
                    false,
                    0,
                    0,
                    0
                ])
            }

            let numberOfStands = standMatrix.filter(e => e[0] === s).filter(stand => Number.isInteger(parseInt(stand[2])))
            
            if (direction === 'RIH') {

                if ((numberOfStands.length < standSpan)) {
                    // there are under 5 stands - include all
                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                        if (Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2])))) {
                            standMatrix.filter(e => e[0] === s)[i][3] = true
                        }
                    }
                } else {
                    // there are 5 or more stands - start at the 5th and include every 5th going forward
                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                        if (
                            (Number.isInteger((parseInt((standMatrix.filter(e => e[0] === s)[i][2]))))) &&
                            (Number.isInteger((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan)) &&
                            (((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan) !== 0)
                        ) {
                            standMatrix.filter(e => e[0] === s)[i][3] = true
                        }
                    }
                }
                // include any singles on the end
                
                //a number of stands in a string:
                let numericStands = standMatrix.filter(e => e[0] === s).filter(stand => Number.isInteger(parseInt(stand[2])))
                
                if (numericStands.length > 0) {
                    let includedStands = standMatrix.filter(e => e[0] === s).filter(e => e[3] === true)
                    if (includedStands.length > 0) {
                        maxIncludedStand = includedStands[includedStands.length - 1][1]
                    } 
                   
                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                        if (
                            Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2]))) &&
                            parseInt((standMatrix.filter(e => e[0] === s)[i][1])) > maxIncludedStand
                        ) {
                            standMatrix.filter(e => e[0] === s)[i][3] = true
                        }
                    }
                }

            } else if (direction === 'POOH') {
                let hitLimit = false

                // do not show anything if processing pipe tally and the bit is not in the tally
                if ((alias === 'casing' || alias === 'liner') || (alias === 'pipe' && (bitLocation.type === 'pipe' || !bitLocation.bitLocated))) {

                    // remove rows that are above the bit
                    if ((s === bitLocation.container) && (alias === bitLocation.type)) {
                        if (standMatrix.length > 0) {
                            for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                                if (hitLimit) {
                                    standMatrix.filter(e => e[0] === s)[i][3] = 0
                                }
                                else {
                                    // does this string and stand match where the bit was located?
                                    if (standMatrix.filter(e => e[0] === s)[i][0] === bitLocation.container && Number(standMatrix.filter(e => e[0] === s)[i][1]) >= (bitLocation.identifier)) {
                                        // as soon as the next integer stand is hit, start marking rows to 0 (for removal)
                                        if (Number.isInteger(parseFloat(standMatrix.filter(e => e[0] === s)[i][2]))) {
                                            hitLimit = true
                                        }
                                    }
                                }
                            }
                        }
                    } else if ((s > bitLocation.container) && (alias === bitLocation.type)) {
                        for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                            if (standMatrix.filter(e => e[0] === s)[i][0] === s) {
                                standMatrix.filter(e => e[0] === s)[i][3] = 0
                            }
                        }
                    }
                    standMatrix = standMatrix.filter(x => x[3] !== 0)

                    // always includes stands 1-4 (from strings below and including the bit location)
                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                        if ((s === bitLocation.container) && (standMatrix[i][1] <= bitLocation.identifier)) {
                            if (Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2]))) && (parseInt((standMatrix.filter(e => e[0] === s)[i][2])) < 5)) {
                                standMatrix.filter(e => e[0] === s)[i][3] = true
                            }

                        } else {
                            if (Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2]))) && (parseInt((standMatrix.filter(e => e[0] === s)[i][2])) < 5)) {
                                standMatrix.filter(e => e[0] === s)[i][3] = true
                            }
                        }
                    }

                    // there are 5 or more stands - start at the 5th and include every 5th going forward
                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                        if ((s === bitLocation.container) && (standMatrix[i][1] <= bitLocation.identifier)) {
                            if (standMatrix[i][1] <= bitLocation.identifier) {
                                if (
                                    (Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2])))) &&
                                    (Number.isInteger((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan)) &&
                                    (((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan) !== 0) &&
                                    ((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0) <= bitLocation.identifier)
                                ) {
                                    standMatrix.filter(e => e[0] === s)[i][3] = true
                                }
                            }
                        } else {
                            if (
                                (Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2])))) &&
                                (Number.isInteger((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan)) &&
                                (((parseInt((standMatrix.filter(e => e[0] === s)[i][2])) || 0)/standSpan) !== 0)
                            ) {
                                standMatrix.filter(e => e[0] === s)[i][3] = true
                            }
                    }
                    }

                    // include any singles on the end of all strings
                    let numericStands = standMatrix.filter(e => e[0] === s).filter(stand => Number.isInteger(parseInt(stand[2])))
                    if (numericStands.length > 0) {
                        let includedStands = standMatrix.filter(e => e[0] === s).filter(e => e[3] === true)
                        if (includedStands.length > 0) {
                            maxIncludedStand = includedStands[includedStands.length - 1][1]
                        }

                        for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                            if (
                                Number.isInteger(parseInt((standMatrix.filter(e => e[0] === s)[i][2]))) &&
                                parseInt((standMatrix.filter(e => e[0] === s)[i][1])) > maxIncludedStand
                            ) {
                                standMatrix.filter(e => e[0] === s)[i][3] = true
                            }
                        }
                    }

                    // process the last string with a true in it
                    let lastStringToShow = 0
                    try { lastStringToShow = standMatrix.filter(e => e[3] === true)[standMatrix.filter(e => e[3] === true).length - 1][0] }
                    catch { lastStringToShow = -1 }

                    // last string case handling (2nd last string is [ tally.strings.length - 2 ])
                    if (tally.strings.length > 0) {
                        // if (s === (tally.strings.length - 1)) {
                        if (s <= lastStringToShow) {
                            // if the string ends in a 5 count stand, show the previous 4 stands as well
                            // are there more than 5 stands in this string?
                            if (Math.max.apply(Math, standMatrix.filter(e => e[0] === s).filter(c => c[3] === true).map(v => v[2])) >= standSpan) {
                                if (Number.isInteger((Math.max.apply(Math, standMatrix.filter(e => e[0] === s).filter(c => c[3] === true).map(v => v[2])) || 0)/standSpan)) {
                                    // the last stand is evenly divisble by 5 - include the previous 4
                                    for (let i = 0; i < standMatrix.filter(e => e[0] === s).length; i++) {
                                        if (
                                            parseInt(standMatrix.filter(e => e[0] === s)[i][2]) >
                                            Math.max.apply(Math, standMatrix.filter(e => e[0] === s).filter(c => c[3] === true).map(v => v[2])) - standSpan
                                        ) {
                                            standMatrix.filter(e => e[0] === s)[i][3] = true
                                        }
                                    }
                                } else {
                                    // the string does not end in a 5 count
                                    // how far is there to walk back? There must be minimum 6 stands to walk back 5
                                    if (Math.max.apply(Math, standMatrix.filter(e => e[0] === s).filter(c => c[3] === true).map(v => v[2])) >= standSpan + 1) {
                                        // get the prior 5 mark and walk to the end from there marking them included in the tripsheet
                                        numberedStands = standMatrix
                                            .filter(e => e[0] === s)
                                            .filter(stand => Number.isInteger(parseInt(stand[2])))
                                        includedNumberedStands = numberedStands.filter(stand => Number.isInteger(stand[2]/standSpan))

                                        for (let i = 0; i < numberedStands.length; i++) {
                                            // NOTE:  includedNumberedStands[includedNumberedStands.length-2][2] - 1 ===> this is one less than the 2nd last 5 mark
                                            if (includedNumberedStands.length > 1) {
                                                if (i > includedNumberedStands[includedNumberedStands.length-2][2] - 1) {
                                                        numberedStands[i][3] = true
                                                    // }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // console.log('matrix', direction, JSON.stringify(standMatrix))
        return standMatrix
    }




    function genStandLengths(standMatrix: any, tally: PipeTallyInstance, direction: string) {
        // generate the stand lengths for tripsheet calculations

        let standLength = 0
        let tripSheetRowLength = 0
        //a number of strings
        let bitString = Math.max(...standMatrix.map((x: any[]) => x[0]))
        //a number of pipes in one string
        let bitStand = Math.max(...standMatrix.filter((e: number[]) => e[0] === bitString).map((x: any[]) => x[1]))

        for (let s = 0; s < tally.strings.length; s++) {
            standLength = 0
            tripSheetRowLength = 0

            if (s <= bitString) {
                for (let i = 0; i < tally.strings[s].list.data.length; i++) {
                    if (((s === bitString) && (i < bitStand)) || (s !== bitString)) {
                       
                        // log the length of the joint
                        standMatrix.filter((e: number[]) => e[0] === s)[i][4] = tally.strings[s].list.data[i].length
                        
                        // length of one stand
                        standLength += tally.strings[s].list.data[i].length
                        //
                        tripSheetRowLength += tally.strings[s].list.data[i].length
                        
                        //if marker is a number put stendLength into standMatrix: 
                        if (parseInt(standMatrix.filter((e: number[]) => e[0] === s)[i][2])) {
                            // update the stand length value in the matrix with the accumulator
                            standMatrix.filter((e: number[]) => e[0] === s)[i][5] = standLength
                             
                            if (standMatrix.filter((e: number[]) => e[0] === s)[i][3] === true) {
                                // update the tripsheet row value in the matrix with the accumulator
                                standMatrix.filter((e: number[]) => e[0] === s)[i][6] = tripSheetRowLength
                                // reset the tripSheetRowLength to 0 for the next tripsheet row
                                tripSheetRowLength = 0
                            }
                        // reset the standLength to 0 for the next numeric stand
                            standLength = 0
                        }
                    }
                }
            }
        }

        // in case the direction is POOH walk backwards and reset the tripsheet row lengths overwritting the RIH-calculated ones
        if (direction === 'POOH') {
            tripSheetRowLength = 0

            for (let i = standMatrix.length - 1; i >= 0; i--) {
                tripSheetRowLength += standMatrix[i][5]

                if (standMatrix[i][3] === true) {
                    standMatrix[i][6] = tripSheetRowLength
                    tripSheetRowLength = 0
                } else {
                    standMatrix[i][6] = 0
                }
            }
        }
        //console.log('standMatrix', standMatrix)
        return standMatrix
    }

    function genTripSheetTallyRows(tally: PipeTallyInstance, alias: string, direction: string, bitLocation: BitLocation) {
        //convert a tallies rows into tripsheet rows

        if (TripSheet && tally.strings.length > 0 && tally.strings[0].list.data.length > 0 && PipeTally) {

            let lengthStand = 0
            let lengthStandAcc = 0
            let calcIncremAcc = 0
            

            // get stand matrix
            let standMatrix = genStandMatrix(tally, direction, alias, bitLocation)

            // calculate tripsheet lengths
            standMatrix = genStandLengths(standMatrix, tally, direction)

            // mark all existing tripsheet tally rows as deleted
            for (let i = 0; i < TripSheet.rows.length; i++) {
                if (TripSheet.rows[i].source === alias) {
                    TripSheet.markDeleted(TripSheet.rows[i].id)
                }
            }

            // walk forward through the stand matrix and create tripsheet rows
            for (let s = 0; s < tally.strings.length; s++) {

                let index = 0
                let string = tally.strings[s]
                let pipe = tally.strings[tally.strings[s].number-1].getPipe(tally.strings[tally.strings[s].number-1].name || '')

                lengthStand = 0
                lengthStandAcc = 0
                calcIncremAcc = 0

                for (let i = 0; i < tally.strings[s].list.data.length; i++) {

                    lengthStand = tally.strings[s].list.data[i].length
                    lengthStandAcc += lengthStand
                    // default to open row setting if no existing value
                    if ((TripSheet.getTripSheetRow(string.list.data[i].id)?.openClosed === 'open') || (TripSheet.getTripSheetRow(string.list.data[i].id)?.openClosed === undefined) || (TripSheet.getTripSheetRow(string.list.data[i].id)?.openClosed === ''))  {
                        calcIncremAcc = lengthStandAcc * (Number(getStatistic('volumeDistanceTripTallyRow', locationState)) * pipe.disp)
                    } else if (TripSheet.getTripSheetRow(string.list.data[i].id)?.openClosed === 'closed') {
                        calcIncremAcc = lengthStandAcc * ((Number(getStatistic('volumeDistanceTripTallyRow', locationState)) * pipe.disp) + (Number(getStatistic('volumeDistanceTripTallyRow', locationState)) * pipe.cap))
                    }

                    if (PipeTally.editingMode === 'POOH') {
                        calcIncremAcc = calcIncremAcc * -1
                    }

                    // does the standMatrix say this stand should be included in the tripsheet?
                    if ((standMatrix.filter(e => e[0] === s).filter(e => e[1] === i + 1).filter(e => e[3] === true)).length > 0) {

                        index = (s * 10000) + i
                        if (alias === 'casing') { index += 2000000 }
                        else if (alias === 'liner') { index += 3000000 }
                        else if (alias === 'pipe') { index += 4000000 }

                        
                        TripSheet?.createTripSheetRow({
                            index: index,
                            source: alias,
                            sourceId: string.list.data[i].id,
                            deleted: false,
                            pipeTypeId: string.pipeTypeId,
                            pipeId: string.name,
                            standLength: string.standLength,
                            string: s,
                            standNumber: parseInt(standMatrix.filter(e => (e[0] === s) && (e[1] === i + 1))[0][2]),
                            length: standMatrix.filter(e => e[0] === s)!.find(e => e[1] === (string.list.data[i].standNumber || 0))![6],
                            serialNumber: string.list.data[i].serialNumber,
                            description: pipe.name,
                            partType: pipe.type,
                            OD: pipe.OD,
                            ID: pipe.ID,
                            disp: Number(getStatistic('volumeDistanceTripTallyRow', locationState)) * pipe.disp, 
                            cap: Number(getStatistic('volumeDistanceTripTallyRow', locationState)) * pipe.cap,
                            weight: Number(getStatistic('weightDistance', locationState)) * pipe.weight,
                            emptyFill: TripSheet.getTripSheetRow(string.list.data[i].id)?.emptyFill || '',
                            tt1: TripSheet.getTripSheetRow(string.list.data[i].id)?.tt1 || 0,
                            tt2: TripSheet.getTripSheetRow(string.list.data[i].id)?.tt2 || 0,
                            openClosed: TripSheet.getTripSheetRow(string.list.data[i].id)?.openClosed || 'open',
                            measHoleIncrem: 0,
                            measHoleAccum: 0,
                            calcIncrem: calcIncremAcc,
                            calcAccum: 0,
                            discIncrem: 0,
                            discAccum: 0,
                            comment: standMatrix.filter(e => e[0] === s).filter(e => e[1] === i + 1).toString(),
                            tsComment: TripSheet.getTripSheetRow(string.list.data[i].id)?.tsComment || '',
                        })
                        lengthStandAcc = 0
                    }
                }
            }
        }
    }

    function genTripSheetBhaRows(bha: BHAInstance, alias: string, direction: string, bitLocation: BitLocation) {
        // convert bha rows into tripsheet rows

        if (BHA && BHA.parts.length > 0 && PipeTally && TripSheet) {

            let existingTripSheetRowId = ''
            let calcIncremAcc = 0

            if (PipeTally.editingMode === 'RIH') {
                for (let i = BHA?.parts.length - 1; i >= 0; i--) {

                    // default to open if not existing value
                    if ((TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === 'open') || (TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === undefined) || (TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === '')) {
                        calcIncremAcc = BHA.parts[i].length * (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState)))
                    } else if (TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === 'closed') {
                        calcIncremAcc = BHA.parts[i].length * (
                            (BHA.parts[i].ID * BHA.parts[i].ID) / Number(getStatistic('bhaCapMultiplier', locationState))
                            + (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState)))
                            )
                    }

                    // if there is an existing row under the same id, mark it deleted
                    existingTripSheetRowId = TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.id || ''
                    if (existingTripSheetRowId.length > 0) {

                        TripSheet?.markDeleted(existingTripSheetRowId)
                    }

                    TripSheet?.createTripSheetRow({
                        index: 1000000 - i,
                        source: 'bha',
                        sourceId: BHA.parts[i].id,
                        deleted: false,
                        pipeTypeId: 'DP',
                        pipeId: '',
                        standLength: '1',
                        string: 1,
                        standNumber: BHA?.parts.length - i,
                        length: BHA.parts[i].length,
                        serialNumber: BHA.parts[i].serialNumber,
                        description: BHA.parts[i].description,
                        partType: BHA.parts[i].partType,
                        OD: BHA.parts[i].OD,
                        ID: BHA.parts[i].ID,
                        disp: (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState))),
                        cap: ((BHA.parts[i].ID * BHA.parts[i].ID) / Number(getStatistic('bhaCapMultiplier', locationState))),
                        weight: BHA.parts[i].weight,
                        emptyFill: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.emptyFill || '',
                        tt1: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tt1 || 0,
                        tt2: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tt2 || 0,
                        openClosed: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.openClosed || 'open',
                        measHoleIncrem: 0,
                        measHoleAccum: 0,
                        calcIncrem: calcIncremAcc,
                        calcAccum: 0,
                        discIncrem: 0,
                        discAccum: 0,
                        comment: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.comment || '',
                        tsComment: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tsComment || '',
                    })
                }
            } else if (PipeTally.editingMode === 'POOH') {
                for (let i = 0; i < BHA?.parts.length; i++) {

                    // default to open setting if no existing tripsheet value
                    if ((TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === 'open') || (TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === undefined) || (TripSheet.getTripSheetRow(BHA.parts[i].id)?.openClosed === '')) {
                        calcIncremAcc = BHA.parts[i].length * (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState)))
                    } else {
                        calcIncremAcc = BHA.parts[i].length * (
                            (BHA.parts[i].ID * BHA.parts[i].ID) / Number(getStatistic('bhaCapMultiplier', locationState))
                            + (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState)))
                            )
                    }

                    calcIncremAcc = calcIncremAcc * -1

                    existingTripSheetRowId = TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.id || ''
                    if (existingTripSheetRowId.length > 0) {
                        TripSheet?.markDeleted(existingTripSheetRowId)
                    }

                    // only create a new row if it is below or at the bit
                    if (bitLocation.bitLocated || (!bitLocation.bitLocated && i >= bitLocation.container)) {
                        TripSheet?.createTripSheetRow({
                            index: 1000000 - i,
                            source: 'bha',
                            sourceId: BHA.parts[i].id,
                            deleted: false,
                            pipeTypeId: 'DP',
                            pipeId: '',
                            standLength: '1',
                            string: 1,
                            standNumber: BHA?.parts.length - i,
                            length: BHA.parts[i].length,
                            serialNumber: BHA.parts[i].serialNumber,
                            description: BHA.parts[i].description,
                            partType: BHA.parts[i].partType,
                            OD: BHA.parts[i].OD,
                            ID: BHA.parts[i].ID,
                            disp: (BHA.parts[i].weight / Number(getStatistic('bhaDispMultiplier', locationState))),
                            cap: ((BHA.parts[i].ID * BHA.parts[i].ID) / Number(getStatistic('bhaCapMultiplier', locationState))),
                            weight: BHA.parts[i].weight,
                            emptyFill: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.emptyFill || '',
                            tt1: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tt1 || 0,
                            tt2: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tt2 || 0,
                            openClosed: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.openClosed || 'open',
                            measHoleIncrem: 0,
                            measHoleAccum: 0,
                            calcIncrem: calcIncremAcc,
                            calcAccum: 0,
                            discIncrem: 0,
                            discAccum: 0,
                            comment: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.comment || '',
                            tsComment: TripSheet?.getTripSheetRow(BHA?.parts[i].id)?.tsComment || '',
                        })
                    }
                }
            }
        }
    }


    function deleteTripSheetRows() {
        if (TripSheet) {
            let rowsToDelete = []
            for (let i = 0; i < TripSheet.rows.length; i++) {
                if (TripSheet.rows[i].deleted) {
                    rowsToDelete.push(TripSheet.rows[i].id)
                }
            }
            for (let i = 0; i < rowsToDelete.length; i++) {
                TripSheet.deleteTripSheetRow(rowsToDelete[i])
            }
        }
    }

    function processTripSheetCalculations() {
        if (TripSheet && PipeTally) {
            let tripTankChange = 0
            let calcAccumVal = 0
            let priorDiscAccum = 0

            let priorRow = TripSheetRow.create({
                index: 9999999,
                tt1: TripSheet.stub,
                tt2: 0,
                measHoleIncrem: 0,
                measHoleAccum: 0,
                calcIncrem: 0,
                calcAccum: 0,
                discIncrem: 0,
                discAccum: 0
            })

            // perform flips necessary for POOH
            if (PipeTally.editingMode === 'POOH') {
                if (TripSheet.rows.filter(e => e['source'] === 'bha').length === 0) {
                    // no bha, reverse all rows
                    TripSheet.reverseTripSheetTallyRows('pipe')
                    TripSheet.reverseTripSheetTallyRows('casing')
                    TripSheet.reverseTripSheetTallyRows('liner')
                } else {
                    // there is bha, so reverse only pipe rows
                    TripSheet.reverseTripSheetTallyRows('pipe')
                }
            }

            for (let i = 0; i < TripSheet.rows.length; i++) {
                calcAccumVal = 0
                calcAccumVal = priorRow.calcAccum + TripSheet.rows[i].calcIncrem
                tripTankChange = 0

                if ((TripSheet.rows[i].tt1 === 0 && TripSheet.rows[i].tt2 > 0) || (TripSheet.rows[i].tt1 > 0 && TripSheet.rows[i].tt2 === 0)) {
                    // CASE: there is only one tt value in the current row, subtract the prior row's matching tt value from it
                    if ((TripSheet.rows[i].tt1 > 0) && (TripSheet.rows[i].tt2 === 0)) {
                        tripTankChange = TripSheet.rows[i].tt1 - priorRow.tt1
                    } else if ((TripSheet.rows[i].tt2 > 0) && (TripSheet.rows[i].tt1 === 0)) {
                        tripTankChange = TripSheet.rows[i].tt2 - priorRow.tt2
                    }
                } else if (TripSheet.rows[i].tt1 > 0 && TripSheet.rows[i].tt2 > 0) {
                    // CASE: there are two trip tank values in the current row
                    //        whichever tt has a prior row value, subtract that from the current row's matching tt value
                    // TODO: handle two values in each of current and prior row (should never be entered that way)
                    if (priorRow.tt1 > 0) {
                        tripTankChange = TripSheet.rows[i].tt1 - priorRow.tt1
                    } else if (priorRow.tt2 > 0) {
                        tripTankChange = TripSheet.rows[i].tt2 - priorRow.tt2
                    }
                } else {
                    // CASE: there are no trip tank values in the current row (both 0)

                    // A - if prior row has both 0's as well then tripTankChange is 0
                    if ((priorRow.tt1 === 0) && (priorRow.tt2 === 0)) {
                        tripTankChange = 0
                    }
                    // B - if prior row has one 0 and one value then tt change is the difference
                    // between current row's tt value and matching prior row's tt value
                    else if (((priorRow.tt1 > 0) && (priorRow.tt2 === 0)) || ((priorRow.tt1 === 0) && (priorRow.tt2 > 0))) {
                        if ((priorRow.tt1 > 0) && (priorRow.tt2 === 0)) {
                            tripTankChange = TripSheet.rows[i].tt1 - priorRow.tt1
                        } else if ((priorRow.tt1 === 0) && (priorRow.tt2 > 0)) {
                            tripTankChange = TripSheet.rows[i].tt2 - priorRow.tt2
                        }
                    }
                    // C - if prior row has both tt values and no 0's then....
                    else if ((priorRow.tt1 > 0) && (priorRow.tt2 > 0)) {
                        // should never be both tt 0's in current row and both tt values in prior row
                        // waterfalling of tt entry edits would prevent this
                        // TODO: handle two 0's in current row and two tt values in prior row, use which prior row tt val to calculate?
                        // default of tripTankChange = 0 is already set at top of loop
                    }
                    else {
                        // should not be other cases
                        // default of tripTankChange = 0 is already set at top of loop
                    }
                }

                TripSheet.setIncrementals(
                    TripSheet.rows[i].index,
                    tripTankChange,
                    PipeTally.editingMode === 'RIH' ?
                    tripTankChange - TripSheet.rows[i].calcIncrem :
                        (tripTankChange - TripSheet.rows[i].calcIncrem) * -1
                )

                TripSheet.setAccumulations(
                    TripSheet.rows[i].index,
                    tripTankChange + priorRow.measHoleAccum,
                    calcAccumVal,
                    PipeTally.editingMode === 'RIH' ?
                        priorRow.discAccum + tripTankChange - TripSheet.rows[i].calcIncrem :
                        (priorDiscAccum - (tripTankChange) + TripSheet.rows[i].calcIncrem)
                )

                // set prior row for next row's use
                priorRow = {...TripSheet.rows[i]}
                priorRow.discAccum = PipeTally.editingMode === 'RIH' ?
                    priorRow.discAccum + tripTankChange - TripSheet.rows[i].calcIncrem :
                    (priorDiscAccum - (tripTankChange) + TripSheet.rows[i].calcIncrem)
                priorDiscAccum = PipeTally.editingMode === 'RIH' ?
                    priorRow.discAccum + tripTankChange - TripSheet.rows[i].calcIncrem :
                    (priorDiscAccum - (tripTankChange) + TripSheet.rows[i].calcIncrem)
            }

            // fix for POOH accumulator needed running in reverse direction on initial load
            // takes existing MHI/MHA and recalculates CI/CA and DI/DA for both RIH and POOH
            // TODO: derive alternative solution
            TripSheet.calculateInitialCalcIncrems(locationState)

        } else {
            console.log('no tripsheet')
        }
    }

    function showData() {
        // const x = document.getElementById("tripSheetData");
        // if (x) {
        //     if (x.style.display === "none") {
        //         x.style.display = "block";
        //     } else {
        //         x.style.display = "none";
        //     }
        // }

        // const el = document.querySelector('#tripSheetData')
        // console.log('TripSheet archive', el!.outerHTML.replace('display: none;', 'display: block;'))
    }

    function searchTallyForBit(tally: PipeTallyInstance, tallyType: string, bit: BitLocation) {
        for (let s = 0; s < tally.strings.length; s++) {
            for (let i = 0; i < tally.strings[s].list.data.length; i++) {
                bit.depthTotal += tally.strings[s].list.data[i].length

                if ((!bit.bitLocated && bit.depthRecorded <= bit.depthTotal)) {
                    bit.type = tallyType
                    bit.container = s
                    bit.identifier = i
                    bit.depthWhereFound = bit.depthTotal
                    bit.bitLocated = true
                    //console.log(`Bit is in ${tallyType} in string = ${s + 1} | reference-stand-number = ${i + 1} | pipe serial number = ${tally.strings[s].list.data[i].serialNumber}`)
                }
            }
        }
        return bit
    }

    function whereIsTheBit() {
        // for POOH only
        const bitDepthTrendSheet = TrendSheet?.rows[TrendSheet.rows.length - 1]?.depth || 0
        let bitLocation = {} as BitLocation
        bitLocation.depthRecorded = bitDepthTrendSheet
        bitLocation.bitLocated = false
        bitLocation.depthTotal = 0
        bitLocation.container = 0

            // POOH direction only
            if (PipeTally && PipeTally.editingMode === 'POOH') {

                // start looking for the bit at the bottom and work up
                // is there a casing or liner?
                if ((CasingTally && CasingTally.strings.length > 0 && CasingTally.strings[0].list.data.length > 0) || (LinerTally && LinerTally.strings.length > 0 && LinerTally.strings[0].list.data.length > 0)) {
                    if (!bitLocation.bitLocated && LinerTally && LinerTally.strings.length > 0 && LinerTally.strings[0].list.data.length > 0) {
                        bitLocation = searchTallyForBit(LinerTally, 'liner', bitLocation)
                    }
                    if (!bitLocation.bitLocated && CasingTally && CasingTally.strings.length > 0 && CasingTally.strings[0].list.data.length > 0) {
                        bitLocation = searchTallyForBit(CasingTally, 'casing', bitLocation)
                    }
                } else {
                    // look for bit in BHA if there is no casing/liner
                    if (!bitLocation.bitLocated) {
                        if (BHA && BHA.parts.length > 0) {
                            // for (let i = 0; i < BHA.parts.length; i++) {
                            for (let i = BHA?.parts.length - 1; i >= 0; i--) {
                                bitLocation.depthTotal += BHA.parts[i].length
                                if ((!bitLocation.bitLocated && bitDepthTrendSheet <= bitLocation.depthTotal)) {
                                    bitLocation.type = 'bha'
                                    bitLocation.container = i
                                    bitLocation.identifier = 0
                                    bitLocation.depthWhereFound = bitLocation.depthTotal
                                    //console.log('Bit is in the BHA at part number = ' + BHA.parts[i].description)
                                    bitLocation.bitLocated = true
                                }
                            }
                        }
                    }
                }

                // if bit not found look in Pipe Tally for it
                if (!bitLocation.bitLocated && TripSheet && PipeTally && PipeTally.strings.length > 0 && PipeTally.strings[0].list.data.length > 0) {
                    bitLocation = searchTallyForBit(PipeTally, 'pipe', bitLocation)
                }
            }
            // console.log('bitLocation', bitLocation)
        return bitLocation
    }

    //console.log('TripSheet', TripSheet)

    // let data=window.performance.getEntriesByType("navigation")[0].toJSON()
    // let jdata = JSON.parse(JSON.stringify(data))
    // // console.log(jdata.type)
    // if (jdata.type === "reload") {
    //   window.location.href = '/dashboard'
    // }

    function generateDownload() {
        if (TripSheet && TripSheet.rows.length > 0) {
            let download = JSON.parse(JSON.stringify(TripSheet.rows))
            let origDisp = 0
            download.forEach(function(x:any){
                origDisp = x.disp
                x.disp = locationState?.units !== 'hybrid' ?
                    x.disp.toFixed(4)
                    : (x.disp * Number(getStatistic('tripDispCap', locationState))).toFixed(4)
                x.cap = locationState?.units !== 'hybrid' ?
                    (origDisp + x.cap).toFixed(4)
                    : Number((origDisp * Number(getStatistic('tripDispCap', locationState))) + (x.cap * Number(getStatistic('tripDispCap', locationState)))).toFixed(4)
                x.length = locationState?.units !== 'hybrid' ?
                    x.length.toFixed(4)
                    : (unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(x.length))).toFixed(4)
                x.weight = locationState?.units !== 'hybrid' ?
                    x.weight.toFixed(3)
                    : (unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(x.weight))).toFixed(3)
                delete x.id
                delete x.index
                delete x.sourceId
                delete x.deleted
                delete x.name
                delete x.pipeTypeId
                delete x.pipeId
                delete x.emptyFill
                delete x.comment
            })
            return download
        } else {
            return []
        }
    }

    return (
        <div>
            <SectionTabs />
            <div id="tripSheetWrapper" style={{ marginLeft: '10px', marginTop: '13px' }}>
                <Settings />
                <TableToolbar />
                <TripTable />
                <div id="csv" className="downloadButtonWrapper">
                    <CSVLink data={generateDownload()} filename={`${cleanUpRogueCharacters(window.location.href.split('/')[5])}-TripSheet-${new Date().toISOString()}_UTC.csv`}>CSV</CSVLink>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <button onClick={showData} style={{ backgroundColor: 'transparent', border: 'none', color: '#73749660', cursor: 'pointer' }}>
                        {(new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ")} UTC
                    </button>
                </div>
                <div id="tripSheetData" style={{ backgroundColor: '#fff', color: '#000000', margin: '15px', padding: '15px', display: 'none' }}>
                    <table className="tbl codeTable">
                        <thead>
                            <tr>
                                <td className="codeTable">index</td>
                                <td className="codeTable" style={{ width: '55px' }}>source</td>
                                <td className="codeTable" style={{ width: '55px' }}>desc</td>
                                <td className="codeTable" style={{ width: '55px' }}>string</td>
                                <td className="codeTable" style={{ width: '45px' }}>stand</td>
                                <td className="codeTable">length</td>
                                <td className="codeTable">weight</td>
                                <td className="codeTable">disp</td>
                                <td className="codeTable">cap</td>
                                <td className="codeTable" style={{ width: '55px' }}>open?</td>
                                <td className="codeTable">tt1</td>
                                <td className="codeTable">tt2</td>
                                <td className="codeTable">MHI</td>
                                <td className="codeTable">MHA</td>
                                <td className="codeTable">CI</td>
                                <td className="codeTable">CA</td>
                                <td className="codeTable">DI</td>
                                <td className="codeTable">DA</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="codeTable" colSpan={1}></td>
                                <td className="codeTable" colSpan={1}>stub</td>
                                <td className="codeTable" colSpan={8}></td>
                                <td className="codeTable">{TripSheet?.stub.toFixed(1)}</td>
                                <td className="codeTable" colSpan={7}></td>
                            </tr>
                            {TripSheet?.rows.map((row, index) => (
                                <tr key={index}>
                                    <td className="codeTable">{row.index}</td>
                                    <td className="codeTable">{row.source}</td>
                                    <td className="codeTable">{row.description}</td>
                                    <td className="codeTable">{row.string}</td>
                                    <td className="codeTable">{row.standNumber}</td>
                                    <td className="codeTable">{row.length.toFixed(1)}</td>
                                    <td className="codeTable">{row.weight}</td>
                                    <td className="codeTable">{row.disp.toFixed(4)}</td>
                                    <td className="codeTable">{(row.disp + row.cap).toFixed(4)}</td>
                                    <td className="codeTable">{row.openClosed}</td>
                                    <td className="codeTable">{row.tt1.toFixed(1)}</td>
                                    <td className="codeTable">{row.tt2.toFixed(1)}</td>
                                    <td className="codeTable">{row.measHoleIncrem.toFixed(1)}</td>
                                    <td className="codeTable">{row.measHoleAccum.toFixed(1)}</td>
                                    <td className="codeTable">{row.calcIncrem.toFixed(1)}</td>
                                    <td className="codeTable">{row.calcAccum.toFixed(1)}</td>
                                    <td className="codeTable">{row.discIncrem.toFixed(1)}</td>
                                    <td className="codeTable">{row.discAccum.toFixed(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <br />
            </div>
        </div>
    )
})

export default TripSheetUi
