import { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useMst } from 'app/store'
import { observer } from 'mobx-react-lite'
import Select from '../kit/Select'
import { AddButton } from '../kit/SmallWidthButton'
import { Paper } from '../Layout'
import { useLocation } from 'react-router-dom'
import { ChipTab } from '../kit/Tabs'
import { confirm } from "react-confirm-box";
import { useParams } from 'react-router'
import { underscoreToSpace } from 'utils/helpers'
import warning from '../../../assets/images/icons/icon_warning.svg'
import { getCompany, getRig } from '../../../utils/helpers'
import { Toast } from '../kit/Toast'

const getLibraryPipes = gql`
  query getPipes($type: String!, $rigId: String!) {
    getPipes(
      type: $type,
      rigId: $rigId
    ) {
      id
      type
      name
      connection
      OD
      ID
      weight
      disp
      cap
      range
      mutMin
      mutMax
      tenStrength
      torStrength
      standard
      rigId
    }
  }
`

const Settings = observer(() => {
  const {
    store: { PipeTally, TripSheet }
  } = useMst()

  const [editingMode, setEditingMode] = useState('RIH')
  const [flipped, setFlipped] = useState(false)
  const [getFreshPipes, setGetFreshPipes] = useState(true)
  const [pipesPopulated, setPipesPopulated] = useState(false)
  const [pipeData, setPipeData] = useState([])
  const [pipeTypeId, setPipeTypeId] = useState('DP')
  const { wellName, wellSectionName } = useParams()
  const [msg, setMsg]= useState('')
  const [showMsg, setShowMsg] = useState(false)
  const [variant, setVariant] = useState('success')
  const location = useLocation()
  const uri = location.pathname

  const { refetch, loading, error } = useQuery(getLibraryPipes, {
    // fetchPolicy: 'cache-and-network',
    variables: { type: pipeTypeId, rigId: getRig() },
    notifyOnNetworkStatusChange: true,
    skip: !getFreshPipes,
    onCompleted(data) {
      if (data) {
        setPipeData(data.getPipes)
      }
      setGetFreshPipes(false)
      setPipesPopulated(true)
    }
  })

  useEffect(() => {
    refreshPipes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri])

  async function refreshPipes() {
    setGetFreshPipes(true)
    await refetch()
  }

  if (loading) {
    // console.log('loading...')
  }
  if (error) console.log(`Error retrieving fresh pipes: ${error.message}`)

  useEffect(() => {
    if (!pipesPopulated)
      setGetFreshPipes(true)
  }, [pipesPopulated])

  useEffect(() => {
    setGetFreshPipes(true)
  }, [])

  useEffect(() => {
    if (PipeTally?.currentString && PipeTally?.currentString?.pipeTypeId !== pipeTypeId) {
      setPipeTypeId(PipeTally.currentString.pipeTypeId)
      setGetFreshPipes(true)
    }

    if ((pipeTypeId === '') || (PipeTally?.currentString.name === undefined)) {
      setPipeTypeId('DP')
    }

    if (getFreshPipes) {
      refetch({ type: pipeTypeId })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFreshPipes, PipeTally?.currentString.name])

  useEffect(() => {
    if (PipeTally?.editingMode === undefined || PipeTally?.editingMode === 'disabled') {
      setEditingMode('RIH')
    }
  }, [editingMode, PipeTally?.editingMode])

  function stringChange(event) {
    if (PipeTally && PipeTally?.currentString?.name?.length > 0) {
      setPipesPopulated(false)
      let elem = document.getElementById('currentPipeId')
      if (elem != null) {
        if (elem.options.length > 0) {
          let ddContainsItem = false
          for (let i = 0; i < elem.options.length; i++) {
            if (PipeTally.currentString.name === elem.options[i].value) {
              ddContainsItem = true
            }
          }

          if (!ddContainsItem) {
            PipeTally.currentString.setPipeName(String(elem.options[0].value))
          }
        }
      }
      refetch()
    }

    PipeTally.setCurrentString(event.target.value)
    setGetFreshPipes(true)
  }

  function typeChange(event) {
    PipeTally?.currentString.setPipeTypeId(String(event.target.value))
    setPipeTypeId(String(event.target.value))
    setTimeout(() => {
      const defaultPipe = document.getElementById('currentPipeId')
      PipeTally?.currentString.setPipeName(String(defaultPipe?.options[0].value))
    }, 1000);
    setGetFreshPipes(true)
  }

  function nameChange(event) {
    PipeTally?.currentString.setPipeName(String(event.target.value))
  }

  const [createSnapshot] = useMutation(
    gql`
      mutation createSnapshot($well: String!, $wellSection: String!, $type: String!, $rig: String!, $company: String!, $data: JSON!) {
        createSnapshot(
            well: $well
            wellSection: $wellSection
            type: $type
            rig: $rig
            company: $company
            data: $data
        ) {
          id
        }
      }
    `,
    {
      variables: {
        well: wellName ? underscoreToSpace(wellName) : '',
        wellSection: wellSectionName ? underscoreToSpace(wellSectionName) : '',
        type: 'tripSheet|' + PipeTally?.editingMode,
        company: getCompany(),
        rig: getRig(),
        data: JSON.stringify(TripSheet)
    },
      onCompleted() {
        console.log('Snapshot created')
      },
    }
  )

  const onClick = async (options, direction) => {
    if (!flipped) {
      if (direction !== PipeTally?.editingMode) {
        const result = await confirm(direction, options);
        if (result) {
          createSnapshot()
          if (direction === 'RIH') {
              TripSheet?.clearTripSheetRows()
              setEditingMode('RIH')
              PipeTally?.setEditingMode('RIH')
          } else if (direction === 'POOH') {
              TripSheet?.clearTripSheetRows()
              setEditingMode('POOH')
              PipeTally?.setEditingMode('POOH')
          }
          setFlipped(true)
          return;
        }
      }
    } else {
      setVariant('error')
      setMsg('Visit the Trip Sheet before re-flipping')
      setShowMsg(true)
      setTimeout(() => {
          setShowMsg(false)
      }, 2000)
  }
  }

  const changeDirection = {
    closeOnOverlayClick: true,
    render: (message, onConfirm, onCancel) => {
        return (
            <div className="centeredDiv" style={{ backgroundColor: 'firebrick' }}>
                <div style={{ width: '600px', margin: '20px', padding: '20px', marginBottom: '15px' }}>
                    <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                        <img alt="warning" src={warning} style={{ marginTop: '10px', width: '75px', height: '75px', marginRight: '10px' }} />
                    </div>
                    <div style={{ color: '#FFFFFF', fontWeight: 500, fontSize: '14pt', marginBottom: '15px' }}>
                        Are you sure you want to change direction to {message}?
                    </div>
                    <div style={{ color: '#FFFFFF95', marginBottom: '25px' }}>
                        This will <span style={{ color: '#FFFFFF', fontWeight: 800 }}>clear</span> any
                        existing manually entered Trip Sheet directional data (such as triptank readings, comments, etc).
                    </div>
                    <button onClick={onConfirm} className="popupAlertButton" style={{ marginRight: '15px' }}> Confirm </button>
                    <button onClick={onCancel} className="popupAlertButton"> Cancel </button>
                </div>
            </div>
        )
    }
  }

  useEffect(() => {
    if (PipeTally && PipeTally.currentString) {
      if (PipeTally.currentString.name === undefined) {
        let elem = document.getElementById('currentPipeId')
        if (elem != null) {
          if (elem.options.length > 0) {
            PipeTally.currentString.setPipeName(String(elem.options[0].value))
          }
        }
      }
    }  
  })

  return (
    <Paper style={{ marginBottom: 16 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <h3 style={{ marginLeft: 8, marginBottom: 8, marginTop: 8 }}>
            String {PipeTally?.currentString.number}
          </h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '10px' }}>
            <ChipTab
                active={PipeTally?.editingMode === 'RIH'}
                // disabled={editingMode === 'RIH'}
                onClick={() => {
                    onClick(changeDirection, 'RIH')
                }}
            >
                RIH
            </ChipTab>
            <ChipTab
                active={PipeTally?.editingMode === 'POOH'}
                // disabled={editingMode === 'POOH'}
                onClick={() => {
                    onClick(changeDirection, 'POOH')
                }}
                >
                POOH
            </ChipTab>
            <Toast
              variant={variant}
              message={`${msg}`}
              open={showMsg}
            />
        </div>
      </div>
      <div style={{ borderRadius: '8px', backgroundColor: 'rgb(58, 60, 86)', display: 'flex', alignItems: 'baseline' }}>

        <Select
          id="currentString"
          value={PipeTally?.currentString.id}
          onChange={stringChange}
          style={{ backgroundColor: '#2D2E44', fontWeight: 400, color: '#fff', margin: 8, width: 120 }}
          title="Current string"
        >
          {PipeTally?.strings.map(({ id, number }) => (
            <option key={id} value={id}>String {number}</option>
          ))}
        </Select>

        <Select
          id="currentPipeType"
          value={PipeTally?.currentString.pipeTypeId}
          onChange={typeChange}
          style={{ backgroundColor: '#2D2E44', color: '#fff', margin: 8, width: 160 }}
          title="Pipe type"
        >
            <option key={'DC'} value={'DC'}>Drill Collar</option>
            <option key={'DP'} value={'DP'}>Drill Pipe</option>
            <option key={'HWDP'} value={'HWDP'}>Heavyweight Pipe</option>
            <option key={'LS'} value={'LS'}>Landing String</option>
        </Select>

        <Select
          id="currentPipeId"
          value={PipeTally?.currentString.name}
          onChange={nameChange}
          style={{ backgroundColor: '#2D2E44', color: '#ffffff', margin: 8, width: 400 }}
          title="Description"
        >
          {pipeData.map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
        </Select>

        <Select
          id="currentStringLength"
          onChange={(e) => {
            PipeTally?.currentString.setStandLength(e.target.value)
          }}
          style={{ backgroundColor: '#2D2E44', color: '#ffffff', margin: 8, width: 125 }}
          value={PipeTally?.currentString.standLength}
          title="Joints per Stand"
        >
          <option key={'1'} value={'1'}>
            1
          </option>
          <option key={'2'} value={'2'}>
            2
          </option>
          <option key={'3'} value={'3'}>
            3
          </option>
          <option key={'4'} value={'4'}>
            4
          </option>
        </Select>

        <span id="addString">
          <AddButton
            onClick={() => {
              PipeTally.createEmptyString()
              PipeTally?.currentString.setPipeTypeId('DP')
              setPipeTypeId('DP')
              setTimeout(() => {
                const defaultPipe = document.getElementById('currentPipeId')
                PipeTally?.currentString.setPipeName(String(defaultPipe?.options[0].value))
              }, 1000);
              setGetFreshPipes(true)
            }}
            style={{ margin: 8 }}
            title="Add string"
          />
        </span>

      </div>
    </Paper>
  )
})

export default Settings