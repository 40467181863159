import { types, Instance, SnapshotIn } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
// import { MainStoreInstance } from 'app/store'
import {
  adjectives,
  colors,
  uniqueNamesGenerator,
  Config,
} from 'unique-names-generator'
import { images } from './PartTypes'

const pipeNameGeneratorConfig: Config = {
  dictionaries: [adjectives, colors, ['part']],
  separator: '-',
  style: 'lowerCase',
}

export const partNameGen = () => uniqueNamesGenerator(pipeNameGeneratorConfig)

export type BHAPartInstance = Instance<typeof BHAPart>
export type BHAPartCreationArgs = SnapshotIn<typeof BHAPart>
export type PartTypesEnum =
  | 'DP'
  | 'HWDP'
  | 'SpiralDC'
  | 'SlickDC'
  | 'MWDCollar'
  | 'Stabilizer'
  | 'Crossover'
  | 'HoleOpener'
  | 'Jar'
  | 'Accelerator'
  | 'RollerCone'
  | 'PDC'
  | 'Custom'

export const PartTypesArray = [
  'DP',
  'HWDP',
  'SpiralDC',
  'SlickDC',
  'MWDCollar',
  'Stabilizer',
  'Crossover',
  'HoleOpener',
  'Jar',
  'Accelerator',
  'RollerCone',
  'PDC',
  'Custom',
]

const PartTypes = types.enumeration([
  'DP',
  'HWDP',
  'SpiralDC',
  'SlickDC',
  'MWDCollar',
  'Stabilizer',
  'Crossover',
  'HoleOpener',
  'Jar',
  'Accelerator',
  'RollerCone',
  'PDC',
  'Custom',
])

const BHAPart = types
  .model('BHAPart', {
    id: types.optional(types.identifier, uuid),
    serialNumber: types.optional(types.string, partNameGen),
    description: types.string,
    partType: PartTypes,
    length: types.number,
    OD: types.number,
    maxOD: types.number,
    ID: types.number,
    weight: types.number,
    topType: types.string,
    botType: types.string,
    liftSubTJOD: types.maybe(types.number),
    elevatorID: types.maybe(types.number),
    elevatorMarking: types.maybe(types.string),
    insertSize: types.maybe(types.number),
    comment: types.maybe(types.string),
    info: types.maybe(types.string),
    bitNumber: types.maybe(types.string),
    bitNozzles: types.maybe(types.string),
    bitTFA: types.maybe(types.string),
    bitManufacturer: types.maybe(types.string),
    bitDullGradeIn: types.maybe(types.string),
    bitDullGradeOut: types.maybe(types.string),
    shearable: types.maybe(types.boolean),
  })
  .views((self) => ({
    get image() {
      return images[self.partType as keyof typeof images]
    },
    get itemWeight() {
      return self.weight * self.length
    },
    get displacement() {
      // unused, requires metric conversion
      return this.itemWeight / 2747
    },
    get capacity() {
      // unused, requires metric conversion
      return ((self.ID * self.ID) / 1029.4) * self.length
    },
  }))
  .actions((self) => {
    return {
      // template(id: string, comment: string) {
      //   self.field = comment
      // },
    }
  })

export default BHAPart
