const Units = () => {

    return (
        <>
            <div className="unitSelectionTitle">Custom Unit Settings</div>
            <div className="unitSelectionRow">
                <div className="field unitSelectionRowItem">
                    <select id="unitLength" className="formFieldSelect">
                    <option>ft</option>
                    </select>
                    <label htmlFor="unitLength" className="placeholder unitSelectionPlaceholder">
                    Length
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitDiameter" className="formFieldSelect">
                    <option>in</option>
                    </select>
                    <label htmlFor="unitDiameter" className="placeholder unitSelectionPlaceholder">
                    Diameter
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitWeight" className="formFieldSelect">
                    <option>kg</option>
                    </select>
                    <label htmlFor="unitWeight" className="placeholder unitSelectionPlaceholder">
                    Weight
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitPressure" className="formFieldSelect">
                    <option>psi</option>
                    </select>
                    <label htmlFor="unitPressure" className="placeholder unitSelectionPlaceholder">
                    Pressure
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitGradient" className="formFieldSelect">
                    <option>psi/ft</option>
                    </select>
                    <label htmlFor="unitGradient" className="placeholder unitSelectionPlaceholder">
                    Gradient
                    </label>
                </div>
            </div>
            <div className="unitSelectionRow">
                <div className="field unitSelectionRowItem">
                    <select id="unitVolume" className="formFieldSelect">
                    <option>m3</option>
                    </select>
                    <label htmlFor="unitVolume" className="placeholder unitSelectionPlaceholder">
                    Volume
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitCapacity" className="formFieldSelect">
                    <option>volume/length</option>
                    </select>
                    <label htmlFor="unitCapacity" className="placeholder unitSelectionPlaceholder">
                    Capacity
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitFlow"  className="formFieldSelect">
                    <option>m3/hr</option>
                    </select>
                    <label htmlFor="unitFlow" className="placeholder unitSelectionPlaceholder">
                    Flow
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitVelocity" className="formFieldSelect">
                    <option>m/hr</option>
                    </select>
                    <label htmlFor="unitVelocity" className="placeholder unitSelectionPlaceholder">
                    Velocity
                    </label>
                </div>
                <div className="field unitSelectionRowItem">
                    <select id="unitTorque" className="formFieldSelect">
                    <option>Nm</option>
                    </select>
                    <label htmlFor="unitTorque" className="placeholder unitSelectionPlaceholder">
                    Torque
                    </label>
                </div>
            </div>
        </>
    );
}

export default Units