import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { useState, useContext } from 'react'
import { RouterContext } from '../Router'
import addPartIcon from 'app/components/kit/icons/PlusCircleBlue.svg'
import flipBHAIcon from '../../../assets/images/icons/icon_flip.svg'
import trashIcon from '../../../assets/images/icons/icon_trash.svg'
import trashIconGray from '../../../assets/images/icons/icon_trashGray.svg'
import editPart from '../../../assets/images/icons/icon_edit.svg'
import Tower from './Tower'
import AddPart from './AddPart'
import EditPart from './EditPart'
import { Button } from '../kit/SmallWidthButton'
import { SectionTabs } from '../WellSection/SectionTabs'
import { getLabel, getLabelNoBrazilian, getStatistic, unitConversion } from '../../../utils/helpers'
import Select from '../kit/Select'
import { UserContext } from 'App'
import './index.css'

const BHA = observer(() => {
  const { user } = useContext(UserContext)
  const {
    store: { BHA },
  } = useMst()
  const [expandedAdd, setExpandedAdd] = useState(false)
  const [expandedEdit, setExpandedEdit] = useState(false)
  const [editedPart, setEditedPart] = useState('')
  const [flipped, setFlipped] = useState(false)
  const [historyIndex, setHistoryIndex] = useState(0);
  const { locationState } = useContext(RouterContext)

  const isSupport = JSON.stringify(['rig', 'company', 'global'])
        === JSON.stringify(user?.scope)

  return (
    <>
      <SectionTabs />
      <div id="bhaHistory" style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}>
        <Select
          defaultValue={historyIndex}
          title="History entry"
          id='historyEntrySelection'
          onChange={(e) => setHistoryIndex(Number(e.target.value))}
        >
          <option key="Current" value="0">
             Current
          </option>
          {BHA?.history?.toReversed().map((entry, idx ) => {
            const originalIdxPlusOne = BHA?.history?.length - idx;
            return  (
              <option key={"History" + originalIdxPlusOne} value={originalIdxPlusOne}>
                BHA {originalIdxPlusOne}
              </option>
            )
          })}
        </Select>
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 16, marginBottom: 5 }}>
          {historyIndex === 0 ? (<Button small id="saveToHistoryButton" onClick={() => {BHA?.pushHistory()}}>
            Save to history
          </Button>) : null}
          {isSupport ? (<Button small id="removeFromHistoryButton" onClick={() => { setHistoryIndex(0); BHA?.clearHistory(historyIndex) }}>
            Remove {historyIndex === 0 ? "" : "BHA " + (historyIndex) + " from "}history
          </Button>) : null}
        </div>
      </div>
      <div id="bha" style={{ display: 'flex', justifyContent: 'start', gap: '8px' }}>
        <AddPart
          open={expandedAdd}
          onClose={() => {
            setExpandedAdd(false)
          }}
         />
        <EditPart
          open={expandedEdit}
          partId={editedPart}
          partType={BHA?.tally(historyIndex).find(part => part.id === editedPart)?.partType}
          historyIndex={historyIndex}
          partDescription={BHA?.tally(historyIndex).find(part => part.id === editedPart)?.description}
          onClose={() => {
            setExpandedEdit(false)
            setEditedPart('')
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          {historyIndex === 0 ? <Button onClick={() => {setExpandedAdd(!expandedAdd)}} id={'addOrCreateBHAbutton'} title='Add part'>
            <img alt="add part" src={addPartIcon}/>
          </Button> : null}
          <Button onClick={() => {setFlipped(!flipped)}} id={'flipBHAbutton'} title='Flip BHA'>
            <img alt="flip BHA" src={flipBHAIcon}/>
          </Button>
         <Tower flipped={flipped} historyIndex={historyIndex}/>
        </div>
        <table className="bhaTable bha">
          <thead style={{ display: 'table-header-group' }}>
            <tr>
              {historyIndex === 0 ? (<th style={{ width: 42 }}><img alt="delete part" src={trashIconGray} /></th>) : <></>}
              <th style={{ width: 15 }}>{historyIndex === 0 ? <>EDIT</>:<>VIEW</>}</th>
              <th style={{ width: 27 }}></th>
              <th style={{ width: 120 }}>Desc.
                <span id="shearableLegend">
                  <br/><span style={{ whiteSpace: 'nowrap' }}>(<span style={{ verticalAlign: 'middle', fontSize: 18 }}>*</span> shearable)</span>
                </span>
              </th>
              {/* <th style={{}}>Serial No.</th> */}
              <th style={{ width: 84 }}>
                Top&nbsp;Type<br/>
                Bottom&nbsp;Type
              </th>
              <th style={{ width: 54, whiteSpace: 'nowrap' }}>
                Max&nbsp;OD (in)<br/> 
                ID&nbsp;(in)
              </th>
              <th style={{ width: 59 }}>F/N OD&nbsp;(in)</th>
              <th style={{ width: 57, whiteSpace: 'nowrap' }}>
                Weight ({getLabel('weightDistance', locationState)})<br/>
                Part&nbsp;Weight ({getLabelNoBrazilian('weight', locationState)})
              </th>
              <th style={{ width: 76 }}>Total Weight ({getLabelNoBrazilian('weight', locationState)})</th>
              <th style={{ width: 58, whiteSpace: 'nowrap' }}>
                Disp. ({getLabel('volumeDistance', locationState)})<br/>
                Cap. ({getLabel('volumeDistance', locationState)})
              </th>
              <th style={{ width: 55 }}>Length ({getLabel('lengthMedium', locationState)})</th>
              <th style={{ width: 60 }}>Total Length ({getLabel('lengthMedium', locationState)})</th>
              <th style={{ width: 59 }}>Lift&nbsp;Sub<br/>TJ&nbsp;O.D.</th>
              <th style={{ width: 59 }}>Elevator/<br/>Insert&nbsp;I.D.</th>
              <th style={{ width: 59 }}>Elev.&nbsp;Marking/<br/>Colour&nbsp;Code</th>
              <th style={{ width: 59 }}>Slip/<br/>Insert&nbsp;Size</th>
              <th style={{ minWidth: 120 }}>Comment</th>
            </tr>
          </thead>
            {(() => {
              const tally = flipped ? BHA?.tally(historyIndex).toReversed() : BHA?.tally(historyIndex)
              return tally.map((part, i) => {
                const partIndexInBHA = flipped ? tally.length - 1 - i : i
                return (
                  <tbody key={i} className={part.shearable ? "shearable" : ""}>
                    <tr>
                    {  historyIndex === 0 ? (
                      <td style={{ width: 42 }} rowSpan={2}>
                        <button
                          className="icon-button trash-icon"
                          onClick={() => {BHA.deletePart(partIndexInBHA)}}
                          title='Delete part'
                          style={{ margin: 'auto' }}
                        >
                         <img alt="delete part" src={trashIcon} />
                        </button>
                      </td>
                    ) : <></>
                      }
                      <td style={{ width: 15 }} rowSpan={2}>
                          <button
                            className="small-icon-button edit-icon"
                            onClick={() => {
                              setEditedPart(part.id)
                              setExpandedEdit(true)
                            }}
                            title={historyIndex === 0 ? 'Edit part' : 'View part details'}
                            style={{ width: "24px", height: "24px", margin: 'auto' }}
                          >
                            <img style={{ paddingLeft: '3px' }} alt={historyIndex === 0 ? "edit part" : "view part details"} src={editPart} />
                          </button>
                      </td>
                      <td style={{ width: 27 }} rowSpan={2}>
                        {BHA?.tally(historyIndex).length - partIndexInBHA}.
                      </td>
                      <td style={{ width: 120, textAlign: 'left' }} rowSpan={2}>
                        <div style={{ position: 'relative'}}>
                          <span title={part.description}>
                            {part.description.length > 30 ? part.description.substring(0, 30) + '...' : part.description}
                          </span><br />
                          <span title={part.serialNumber} style={{ color: 'dodgerblue' }}>
                            {part.serialNumber.length > 30 ? part.serialNumber.substring(0, 30) + '...' : part.serialNumber}
                          </span>
                          <span className="shearableIndicator">*</span>
                        </div>
                      </td>
                      <td style={{ width: 84 }}>
                        {part.topType}
                      </td>
                      <td style={{ width: 54 }}>
                        {part.maxOD}"
                      </td>
                      <td style={{ width: 59 }} rowSpan={2}>
                        {part.OD}"
                      </td>
                      <td style={{ width: 57 }}>
                        {unitConversion('lengthMedium', locationState?.units, 'in', Number(part.weight), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 76 }} rowSpan={2}>
                        {unitConversion('noHybridConversion', locationState?.units, 'in', Number(part.totalWeight), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 58 }}>
                        {unitConversion('lengthMedium', locationState?.units, 'in', Number(part.weight) / getStatistic('bhaDispMultiplierBha', locationState), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 55 }} rowSpan={2}>
                        {unitConversion('lengthMedium', locationState?.units, 'out', Number(part.length), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 60 }} rowSpan={2}>
                        {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(part.totalLength), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 59 }} rowSpan={2}>
                        {part.liftSubTJOD ? part.liftSubTJOD : ''}
                      </td>
                      <td style={{ width: 59 }} rowSpan={2}>
                        {part.elevatorID ? part.elevatorID : ''}
                      </td>
                      <td style={{ width: 59 }} rowSpan={2}>
                        {part.elevatorMarking ? part.elevatorMarking : ''}
                      </td>
                      <td style={{ width: 59 }} rowSpan={2}>
                        {part.insertSize ? part.insertSize : ''}
                      </td>
                      <td style={{ width: 120, textAlign: 'left', verticalAlign: 'top' }} rowSpan={2}>
                        <div className='bhaPartComment'>
                          {part.comment ? part.comment : ''}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 84 }}>
                        {part.botType}
                      </td>
                      <td style={{ width: 54 }}>
                        {part.ID}"
                      </td>
                      <td style={{ width: 57 }}>
                        {unitConversion('noHybridConversion', locationState?.units, 'in', Number(part.itemWeight), 15).toFixed(2)}
                      </td>
                      <td style={{ width: 58 }}>
                        {((part.ID * part.ID) / getStatistic('bhaCapMultiplierBha', locationState)).toFixed(3)}
                      </td>
                    </tr>
                  </tbody>
                )
              })
            })()}
        </table>
      </div>
    </>
  )
})

export default BHA
