import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { underscoreToSpace } from 'utils/helpers';
import { Button } from '../kit/SmallWidthButton'
import { getCompany, getRig } from '../../../utils/helpers'
import { RouterContext } from '../Router'
import Select from '../kit/Select'

interface Props {
    onClose: () => void
}

const CopyBha = observer(({ onClose }: Props) => {
    const {
        store: { BHA }
      } = useMst()
      const router = useLocation()
      const [copySelected, setCopySelected] = useState(false)
      const [copied, setCopied] = useState(false)
      const [wellName, setWellName] = useState(underscoreToSpace(router.pathname.split('/')[2]))
      const { locationState } = useContext(RouterContext)


      const [getWells, { data: dataWells, loading: loadingWells }] = useLazyQuery(
        gql`
          query getWells($companyId: String!, $rigId: String!, $take: String!) {
            getWells(
                companyId: $companyId,
                rigId: $rigId,
                take: $take,
              ) {
              id
              name
              units
            }
          }
        `,
        {
          variables: {
            companyId: getCompany(),
            rigId: getRig(),
            take: '500',
          },
          onCompleted(data) {
          },
        }
      )
    
      const [getWellSections, { data, loading }] = useLazyQuery(
        gql`
            query getWellSections($companyId: String!, $rigId: String!, $name: String, $take: String!) {
                getWellSections(
                    companyId: $companyId,
                    rigId: $rigId,
                    name: $name,
                    take: $take,
                ) {
                    id
                    name
                }
            }
        `,
        {
            variables: {
                companyId: getCompany(),
                rigId: getRig(),
                name: wellName,
                take: '1000',
            },
            onCompleted(data) {
                // setIsLoading(true)
            },
        }
    )

    let copyWs = ''
    const copyBhaSelection = document.getElementById('copyBhaSelection') as HTMLSelectElement
    if (copyBhaSelection) {
        copyWs = underscoreToSpace((document.getElementById('copyBhaSelection') as HTMLSelectElement).value)
    }

    const [getBhaToCopy, {data: dataCopy}] = useLazyQuery(
        gql`
          query wellSectionByName($wellSectionName: String!, $wellName: String!, $companyId: String!, $rigId: String!) {
            wellSectionByName(
              wellName: $wellName,
              wellSectionName: $wellSectionName,
              companyId: $companyId,
              rigId: $rigId,
            ) {
              id
              BhaEz {
                id
                wellSectionId
                data
              }
            }
          }
        `,
        {
            variables: {
                wellName: wellName,
                wellSectionName: copyWs,
                companyId: JSON.parse(localStorage.getItem('context') || '').company.id,
                rigId: JSON.parse(localStorage.getItem('context') || '').rig.id,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            onCompleted(dataCopy) {
                copySelectedBha()
                setCopied(true)
                onClose()
            },
            onError(err) {
                console.error(err)
            }
        },
    )

    useEffect(() => {
        getWells()
        getWellSections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleCopy() {
        getBhaToCopy()
    }

    function copyBhaItemSelected() {
        const elem = document.getElementById('copyBhaSelection') as HTMLSelectElement
        if (elem) {
            if (elem.selectedIndex > 0) {
                setCopySelected(true)
            } else {
                setCopySelected(false)
            }
        }
    }

    function copySelectedBha() {
        if (dataCopy && BHA && BHA?.parts.length === 0) {
            if (Object.keys(dataCopy.wellSectionByName.BhaEz.data).length !== 0) {
                for (let i = 0; i < JSON.parse(dataCopy.wellSectionByName.BhaEz.data).parts?.length; i++) {
                    const part = JSON.parse(dataCopy.wellSectionByName.BhaEz.data).parts[i];
                    if (part) {
                        BHA?.addPartUnconditional({
                            OD: part.OD,
                            ID: part.ID,
                            description: part.description,
                            serialNumber: part.serialNumber || undefined,
                            length: part.length,
                            weight: part.weight,
                            maxOD: part.maxOD,
                            partType: part.partType,
                            topType: part.topType,
                            botType: part.botType,
                            liftSubTJOD: part.liftSubTJOD,
                            elevatorID: part.elevatorID,
                            elevatorMarking: part.elevatorMarking,
                            insertSize: part.insertSize,
                            comment: part.comment,
                            info: part.info,
                            bitNumber: part.bitNumber,
                            bitNozzles: part.bitNozzles,
                            bitTFA: part.bitTFA,
                            bitManufacturer: part.bitManufacturer,
                            bitDullGradeIn: part.bitDullGradeIn,
                            bitDullGradeOut: part.bitDullGradeOut,
                            shearable: part.shearable,
                        })
                    }
                }
            }
        }
    }

    return !loading && !copied && data && data.getWellSections ? (
      <div className="" style={{ color: "#fff", marginBottom: '16px'}}>
        <h2 style={{ textAlign: 'center', color: '#FFF', fontWeight: 800, fontSize: '20pt' }}><span style={{ color: 'cornflowerblue' }}>Copy</span> an existing BHA or <span style={{ color: 'cornflowerblue' }}>Create</span> a new BHA?</h2>
        <div style={{ backgroundColor: '#5B5C7455', borderRadius: '16px', padding: '10px' }}>
            <h4 style={{ marginTop: '5px' }}>
                <span className="uiContainerStatusWarning" style={{ marginRight: '15px' }}>COPY &nbsp; BHA</span>
                <span style={{ fontWeight: 500, color: '#E5E4E2' }}>I wish to <span style={{ color: 'cornflowerblue', textDecoration: 'none', fontWeight: 900, marginLeft: '2px', marginRight: '2px' }}>copy</span> the entire BHA from an existing well section on this well</span>
            </h4>
            <div style={{ margin: 'auto', backgroundColor: 'transparent', textAlign: 'center' }}>
                <h3 style={{ marginTop: '50px', color: 'lightslategray', marginBottom: '20px' }}>Copy the BHA from</h3>
                <Select
                    id="copyBhaSelectionWell"
                    title="Well"
                    defaultValue={wellName}
                    onChange={(e) => {
                        const selectElement = e.target as HTMLSelectElement
                        selectElement.blur()
                        setWellName(selectElement.value)
                        getWellSections()
                    }}
                        style={{ margin: 'auto', maxWidth: 300, textAlign: 'center', fontSize: "14px", whiteSpace: "nowrap", fontFamily: "sans-serif", fontWeight: 400, width: 270 }}
                    >
                    {!loadingWells &&
                        dataWells &&
                        dataWells.getWells &&
                        dataWells.getWells
                        .filter((e: { units: string }) => e.units === locationState?.units) // only use wells with the same units
                        .slice().sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name)).map(({ id, name }: { id: string, name: string }) => (
                        <option
                            key={id}
                            value={name}
                            className=""
                            // onClick={() => {
                            //     //   document.getElementById('wellSectionName').focus()
                            //     copyBhaItemSelected()
                            // }}
                            >
                            {name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="copyBhaSelection"
                    title="Well Section"
                    onChange={(e) => {
                        const selectElement = e.target as HTMLSelectElement
                        selectElement.blur()
                        copyBhaItemSelected()
                    }}
                        style={{ margin: 'auto', maxWidth: 300, textAlign: 'center', fontSize: "14px", whiteSpace: "nowrap", fontFamily: "sans-serif", fontWeight: 400, width: 270 }}
                    >
                    <option key="100000001" value="">--- Select Well Section ---</option>
                    {!loading &&
                        data &&
                        data.getWellSections &&
                        data.getWellSections
                        .filter((e: { name: string }) => wellName !== underscoreToSpace(router.pathname.split('/')[2]) || e.name !== underscoreToSpace(router.pathname.split('/')[3]))
                        .slice().sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name)).map(({ id, name }: { id: string, name: string }) => (
                        <option
                            key={id}
                            value={name}
                            className=""
                            // onClick={() => {
                            //     //   document.getElementById('wellSectionName').focus()
                            //     copyBhaItemSelected()
                            // }}
                            >
                            {name}
                        </option>
                    ))}
                </Select>
            </div>
            <br /><br /><br /><br />
            <div style={{ cursor: 'pointer !important', color: 'dimgray', marginTop: '5px', marginBottom: '6px', width: '100%', textAlign: 'right', float: 'right' }}>
                <Button
                    disabled={!copySelected}
                    small
                    onClick={() => {handleCopy()}}
                    style={{
                        cursor: 'pointer !important',
                        marginTop: '-48px',
                        float: 'right'
                    }}>
                    Copy
                </Button>
            </div>
        </div>
    </div>
    ) : null
})

export default CopyBha
