import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { RouterContext } from '../Router'
import { MetricSpacer } from "../Well/MetricSpacer"
import { MudType } from "./MudType"
import Units from '../../store/Units.json'
import { useMst } from 'app/store'
import { getLabel } from '../../../utils/helpers'
import { isAlive } from 'mobx-state-tree'

interface Props {
    wellName?: string
    wellSection?: string
}

const Metrics = observer (({wellName, wellSection}: Props) => {
    const {
        store: { WellM, WellSectionM, TrendSheet, KillSheet },
    } = useMst()
    const { locationState } = useContext(RouterContext)
    const [maasp, setMaasp] = useState(0)
    const [drillDensity, setDrillDensity] = useState(0)
    const [plasticViscosity, setPlasticViscosity] = useState(0)
    const [yieldPoint, setYieldPoint] = useState(0)
    const [mudWeight, setMudWeight] = useState(0)

    useEffect(() => {
        refreshData()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        refreshData()
    }, [KillSheet, WellM, WellSectionM, locationState])  // eslint-disable-line react-hooks/exhaustive-deps

    function refreshData() {
        if (WellSectionM && isAlive(WellSectionM)) {
            if (TrendSheet && isAlive(TrendSheet) && TrendSheet?.rows.length > 0) {
                setMetricValues(
                    locationState!.units || '',
                    WellSectionM?.cp || 0,
                    WellSectionM?.yieldPoint || 0,
                    getLastTrendSheetRow()?.mwOut || 0
                )
            }
        }
    }

    function setMetricValues(_units: string, _cp: number, _yieldPoint: number, _mudWeight: number) {
        let eMdfd = document.getElementById('madfd') as HTMLInputElement
        let eMaasp = document.getElementById('maasp') as HTMLInputElement
        let eYieldPoint = document.getElementById('yieldPoint') as HTMLInputElement
        let eCp = document.getElementById('cp') as HTMLInputElement
        let eMudWeight = document.getElementById('mudWeight') as HTMLInputElement

        if ((KillSheet?.surfaceLeakOffPressure || 0) > 0) {
            setMaasp(getInitialMaasp(_units))
            setDrillDensity(getMaximumAllowableDrillFluidDensity(_units))
        }
        if (WellSectionM) {
            setPlasticViscosity(_cp)
            setYieldPoint(_yieldPoint)
        }
        if (TrendSheet && TrendSheet?.rows.length > 0) {
            setMudWeight(_mudWeight)
        }

        eMdfd.value = drillDensity.toString()
        eMaasp.value = maasp.toString()
        eYieldPoint.value = _yieldPoint.toString()
        eCp.value = _cp.toString()
        eMudWeight.value = _mudWeight.toString()
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            }

    function setInputFieldValue(field: string, value: number) {
        const elem = document.getElementById(field) as HTMLInputElement
        if (elem) {
          elem.value = value.toString()
        }
      }

    function roundDown(number: number, decimals: number) {
        decimals = decimals || 0;
        return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
    }

    function getConversionStatistic(key: string) {
        const conversion = Units[Units.findIndex(({ handle }) => handle === key)]
        if (conversion && locationState) {
          return conversion
        }
    }

    function getLastTrendSheetRow() {
        if (TrendSheet) {
            if (TrendSheet.rows.length > 0) {
                return TrendSheet.rows[TrendSheet.rows.length - 1]
            }
        }
        return null
    }

    function getMaxLinerShoeTvd() {
        if (WellM) {
            if (WellM.LinerShoeTvd5 > 0) {
                return WellM.LinerShoeTvd5
            } else if (WellM.LinerShoeTvd4 > 0) {
                return WellM.LinerShoeTvd4
            } else if (WellM.LinerShoeTvd3 > 0) {
                return WellM.LinerShoeTvd3
            } else if (WellM.LinerShoeTvd2 > 0) {
                return WellM.LinerShoeTvd2
            } else {
                return WellM.LinerShoeTvd1
            }
        }
        return 0
    }

    function getMaxCasingShoeTvd() {
        if (WellM) {
            if (WellM.casingShoeTvd5 > 0) {
                return WellM.casingShoeTvd5
            } else if (WellM.casingShoeTvd4 > 0) {
                return WellM.casingShoeTvd4
            } else if (WellM.casingShoeTvd3 > 0) {
                return WellM.casingShoeTvd3
            } else if (WellM.casingShoeTvd2 > 0) {
                return WellM.casingShoeTvd2
            } else {
                return WellM.casingShoeTvd
            }
        }
        return 0
    }



    function getMaximumAllowableDrillFluidDensity(units: string) {
        let val = 0

        if ((KillSheet?.surfaceLeakOffPressure || 0) > 0) {
            if (WellM) {
                let A = 0
                let B = 0
                let C = 0
                let result = 0

                A = KillSheet?.surfaceLeakOffPressure || 0
                B = KillSheet?.drillingFluidDensity || 0
                if (getMaxLinerShoeTvd() > getMaxCasingShoeTvd()) {
                  C = getMaxLinerShoeTvd()
                } else {
                  C = getMaxCasingShoeTvd()
                }
                result = B + A / (C * (units === 'metric' ?
                    parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                    parseFloat(getConversionStatistic('densityPressure')!.impRate))
                )
                val = roundDown(result, 1)
                if (isNaN(val)) { val = 0 }
                if (!isFinite(val)) { val = 0 }
                return val
              }
        }
        return 0
    }

    function getInitialMaasp(units: string) {
        let val = 0

        if ((KillSheet?.surfaceLeakOffPressure || 0) > 0) {
            if (WellM) {
                let A = 0
                let B = 0
                let C = 0
                let D = 0
                let E = 0
                let F = 0
                let result = 0

                A = KillSheet?.surfaceLeakOffPressure || 0
                B = KillSheet?.drillingFluidDensity || 0
                D = WellM!.casingShoeTvd || 0
                E = getLastTrendSheetRow()?.mwOut || 0

                if (getMaxLinerShoeTvd() > getMaxCasingShoeTvd()) {
                    C = getMaxLinerShoeTvd()
                    F = roundDown(B + A / (C * (units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    )), 1)
                    result = (F - E) * (C * (units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    ))
                } else {
                    C = getMaxCasingShoeTvd()
                    F = roundDown(B + A / (C * (units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    )), 1)
                    result = (F - E) * (D * (units === 'metric' ?
                        parseFloat(getConversionStatistic('densityPressure')!.metRate) :
                        parseFloat(getConversionStatistic('densityPressure')!.impRate)
                    ))
                }
                val = roundDown(result, 0)
                if (isNaN(val)) { val = 0 }
                if (!isFinite(val)) { val = 0 }
                return val
            }
        }

        return 0
    }

    return (
        <div className="metricsWrapper">
            <div className="metricsContainer" style={{ zoom: '75%' }}>
                <div className="metricsPanel">
                    <div className="gridTableSection">
                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <MudType />
                                <div />
                            </div>
                        </div>

                        <MetricSpacer direction="gridTableSpacerVertical" />

                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <div className="metricItemProminentValue">
                                    <input
                                    id="mudWeight"
                                    title="Mud Weight"
                                    type="number"
                                    readOnly={true}
                                    value={mudWeight || 0}
                                    className="metricItemValueInputDisabled"
                                    disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="gridTableItemContainer">
                                <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                                    Mud Weight
                                    <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                                        ({getLabel('weightVolumeSg', locationState)})
                                    </div>
                                </div>
                                <div />
                            </div>
                        </div>

                        <MetricSpacer direction="gridTableSpacerVertical" />

                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <div className="metricItemProminentValue">
                                    <input
                                    id="madfd"
                                    title="Maximum Allowable Drill Fluid Density"
                                    type="number"
                                    readOnly={true}
                                    value={drillDensity.toFixed(2) || 0}
                                    className="metricItemValueInputDisabled"
                                    disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="gridTableItemContainer">
                                <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                                    Max Drill Fluid<br />Density
                                    <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                                        ({getLabel('weightVolumeSg', locationState)})
                                    </div>
                                </div>
                                <div />
                            </div>
                        </div>

                        <MetricSpacer direction="gridTableSpacerVertical" />

                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <div className="metricItemProminentValue">
                                    <input
                                    id="maasp"
                                    title="Initial MAASP"
                                    type="number"
                                    readOnly={true}
                                    value={maasp.toFixed(0) || 0}
                                    className="metricItemValueInputDisabled"
                                    disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="gridTableItemContainer">
                                <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                                    Initial MAASP
                                    <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                                        ({getLabel('pressure', locationState)})
                                    </div>
                                </div>
                                <div />
                            </div>
                        </div>



                        <MetricSpacer direction="gridTableSpacerVertical" />

                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <div className="metricItemProminentValue">
                                    <input
                                    id="yieldPoint"
                                    title="Yield Point"
                                    type="number"
                                    step="0.1"
                                    defaultValue={yieldPoint || 0}
                                    onFocus={e => e.target.select()}
                                    onBlur={(e) => {
                                        WellSectionM?.updateWellSectionData(
                                        WellSectionM.id,
                                        'yieldPoint',
                                        'string',
                                        e.target.value
                                        )
                                        setInputFieldValue('yieldPoint', parseFloat(e.target.value))
                                    }}
                                    className="metricItemValueInput"
                                    />
                                </div>
                            </div>
                            <div className="gridTableItemContainer">
                                <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                                    Yield Point
                                    <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                                        ({getLabel('weightDistanceExponential', locationState)})
                                    </div>
                                </div>
                                <div />
                            </div>
                        </div>

                        <MetricSpacer direction="gridTableSpacerVertical" />

                        <div className="gridTableItemWrapper">
                            <div className="gridTableItemContainer">
                                <div className="metricItemProminentValue">
                                    <input
                                    id="cp"
                                    title="CP"
                                    type="number"
                                    step="0.1"
                                    defaultValue={plasticViscosity || 0}
                                    onFocus={e => e.target.select()}
                                    onBlur={(e) => {
                                        WellSectionM?.updateWellSectionData(
                                        WellSectionM.id,
                                        'cp',
                                        'string',
                                        e.target.value
                                        )
                                        setInputFieldValue('cp', parseFloat(e.target.value))
                                    }}
                                    className="metricItemValueInput"
                                    />
                                </div>
                            </div>
                            <div className="gridTableItemContainer">
                                <div className="gridTableItemLabel" style={{ display: 'flex', flexDirection: 'row' }}>
                                    Plastic Viscosity
                                    <div className="gridTableItemUnit" style={{ fontSize: '12pt', marginLeft: '6px' }}>
                                        (cp)
                                    </div>
                                </div>
                                <div />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
})

export default Metrics