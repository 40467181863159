import OverpullCalculator from './OverpullCalculator'
import { SectionTabs } from '../WellSection/SectionTabs'
import './overpullCalculator.css'

const OverpullSheet = () => {

    return (
        <div className="overpull-calculator-page">
            <SectionTabs />
            <h1 style={{ color: '#fff', marginLeft: '8px', fontSize: '28pt' }}>Overpull Sheet</h1>
            <OverpullCalculator />
        </div>
    )
}

export default OverpullSheet
