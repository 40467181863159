import { cl } from 'dynamic-class-list'
import { FunctionComponent, forwardRef, SVGProps } from 'react'
import { JSXElementProps } from 'utils/helpers'
import './Part.css'

const Part = forwardRef<
  HTMLDivElement,
  {
    labelShown: boolean
    labelTitle: string
    image: FunctionComponent<SVGProps<SVGSVGElement>>
    flipped: boolean
  } & JSXElementProps<HTMLDivElement>
>((p, ref) => {
  const { labelShown, labelTitle, image: Image, flipped, ...props } = p
  return (
    <div {...props} ref={ref} className="part_container">
      <Image className="part_image" style={{transform: flipped ? 'scaleY(-1)' : 'none'}}/>
      <div className={cl({ part_point: true, hidden: !labelShown })}></div>
      <div className={cl({ part_link: true, hidden: !labelShown })}></div>
      <div className={cl({ label_point: true, hidden: !labelShown })}></div>
      <div className={cl({ part_label: true, hidden: !labelShown })}>
        <p>{labelTitle}</p>
      </div>
    </div>
  )
})

export default Part
