import Big from 'big.js'
import { destroy, detach, Instance, types, isAlive } from 'mobx-state-tree'
// import { integer } from 'mobx-state-tree/dist/internal'
import BHAPart, { BHAPartCreationArgs } from './BHAPart'
// import { getUuid } from 'utils/helpers'

const BHAHistoryEntry = types
  .model({
    parts: types.array(BHAPart),
  })

const BHA = types
  .model({
    description: types.optional(types.number, 0),
    parts: types.array(BHAPart),
    history: types.optional(types.array(BHAHistoryEntry), []),
  })
  .views((self) => ({
    tally(idx: number) {
      const parts = idx === 0 ? self.parts : self.history[idx - 1].parts;

      // get sums
      let sumTotalLength = new Big(0)
      let sumTotalWeight = new Big(0)
      let disposable = parts.map(
        ({ itemWeight, ...part }) => {
          sumTotalLength = new Big(sumTotalLength.add(part.length))
          sumTotalWeight = new Big(sumTotalWeight.add(itemWeight))
          return null
        }
      )
      disposable.length = 0

      //use sums to decrement running totals
      let totalLengthAcc = sumTotalLength
      let totalWeightAcc = sumTotalWeight
      let priorLength = 0
      let priorWeight = 0
      return parts.map(
        ({ displacement, capacity, itemWeight, ...part }) => {
          totalLengthAcc = new Big(totalLengthAcc.sub(priorLength))
          totalWeightAcc = new Big(totalWeightAcc.sub(priorWeight))
          priorLength = part.length
          priorWeight = itemWeight
          return {
            ...part,
            totalLength: totalLengthAcc.toFixed(3),
            totalWeight: totalWeightAcc.toFixed(3),
            displacement,
            capacity,
            itemWeight,
          }
        }
      )
    },
  }))
  .actions((self) => ({
    movePart(fromIndex: number, toIndex: number) {
      const lastPartIndex = self.parts.length - 1
      const lastPartType = self.parts[lastPartIndex].partType
      if (
        toIndex === lastPartIndex &&
        (lastPartType === 'RollerCone' || lastPartType === 'PDC')
      ) {
        toIndex = toIndex - 1
      }
      self.parts.splice(toIndex, 0, detach(self.parts[fromIndex]))
    },
    addPart(args: BHAPartCreationArgs) {
      if (self.parts.length !== 0) {
        const lastPartIndex = self.parts.length - 1
        const lastPartType = self.parts[lastPartIndex].partType
        if (args.partType !== 'PDC' && args.partType !== 'RollerCone') {
          self.parts.splice(0, 0, BHAPart.create(args))
        } else if (
          lastPartType &&
          (lastPartType === 'RollerCone' || lastPartType === 'PDC')
        ) {
          self.parts.splice(lastPartIndex, 1, BHAPart.create(args))
        } else self.parts.push(BHAPart.create(args))
      } else self.parts.push(BHAPart.create(args))
    },
    addPartUnconditional(args: BHAPartCreationArgs) {
      self.parts.push(BHAPart.create(args))
    },
    editPart(args: BHAPartCreationArgs) {
      if (self.parts.length !== 0) {
        const partToUpdate = self.parts.find((part) => part.id === args.id)
        if (partToUpdate && args.id !== undefined) {
          // partToUpdate.id = args.id
          // partToUpdate.partType = args.partType
          partToUpdate.length = args.length
          partToUpdate.serialNumber = args.serialNumber || ''
          partToUpdate.weight = args.weight
          partToUpdate.OD = args.OD
          partToUpdate.maxOD = args.maxOD
          partToUpdate.ID = args.ID
          partToUpdate.description = args.description
          partToUpdate.topType = args.topType
          partToUpdate.botType = args.botType
          partToUpdate.liftSubTJOD = args.liftSubTJOD
          partToUpdate.elevatorID = args.elevatorID
          partToUpdate.elevatorMarking = args.elevatorMarking
          partToUpdate.insertSize = args.insertSize
          partToUpdate.comment = args.comment
          partToUpdate.info = args.info
          partToUpdate.bitNumber = args.bitNumber
          partToUpdate.bitNozzles = args.bitNozzles
          partToUpdate.bitTFA = args.bitTFA
          partToUpdate.bitManufacturer = args.bitManufacturer
          partToUpdate.bitDullGradeIn = args.bitDullGradeIn
          partToUpdate.bitDullGradeOut = args.bitDullGradeOut
          partToUpdate.shearable = args.shearable
        }
      }
    },
    deletePart(index: number) {
      destroy(self.parts[index])
    },
    clear() {
      self.parts.clear()
    },
    wipe() {
      self.parts.forEach((part) => {
        if (isAlive(part)) {
          self.parts.remove(part)
        }
      })
    },
    pushHistory() {
      self.history.push({parts: []})
      self.parts.forEach((part) => {
        self.history[self.history.length -1].parts.push({...part})
      })
    },
    clearHistory(index: number) {
      if(self.history) {
        if(index === 0) {
          self.history.clear()
        } else {
          self.history.splice(index - 1, 1)
        }
      }
    },
    // changeGuid(index: number, id: string) {
    //   self.parts[index].id = id
    // },
    // changeGuids() {
    //   self.parts.forEach((part) => {
    //     if (isAlive(part)) {
    //       part.id = getUuid()
    //     }
    //   })
    // },
  }))

export type BHAInstance = Instance<typeof BHA>

export default BHA
