import {Instance,types,SnapshotIn} from "mobx-state-tree"
import { v4 as uuid } from "uuid"

export type OverpullCalculatorInstance = Instance<typeof OverpullCalculator>;
export type OverpullCalculatorSetAttributes = SnapshotIn<typeof OverpullCalculator>;

export type CreateOverpullCalculatortInput = {
  id: string;
}

export const OverpullCalculator = types
  .model("OverpullCalculator", {
    id: types.optional(types.identifier, uuid),
    safetyFactor: types.optional(types.number, 0),
    steelDensity: types.optional(types.number, 0),
  })
  .views((self) => ({
   
  }))
  .actions((self) => {
    return {
      updateOverpullCalculator(id: string, field: any, fieldType: string, value: string) {
          const updateFieldType: string = fieldType
          const updateField: typeof updateFieldType = field
          const updateValue = value

          if (updateField === 'id') {
              self.id = updateValue
          } else if (updateField === 'safetyFactor') {
              self.safetyFactor = parseFloat(updateValue)
          } else if (updateField === 'steelDensity') {
              self.steelDensity = parseFloat(updateValue)
          }
      },

    }
  })

export default OverpullCalculator